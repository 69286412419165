import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../Image';
import BannerArticle from './BannerArticle';
import styles from '../css/singleBanner.css';

const SingleBanner = ({ image, title, description }) => {
  if (!image.src) { return null; }
  return (
    <section className={styles.banner}>
      <Image className={styles.image} src={image} alt={image} />
      <BannerArticle
        description={description}
        title={title}
        stylesArticle={styles.article}
        stylesTextBox={styles.textBox}
      />
    </section>
  );
};

SingleBanner.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string,
};

SingleBanner.defaultProps = {
  description: '',
  image: '',
  title: '',
};

export default SingleBanner;
