import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FilterTypeSelector from './FilterTypeSelector';

import styles from '../css/styles.css';

class FilterContent extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    dictionaries: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    maxPrice: PropTypes.number.isRequired,
    minPrice: PropTypes.number.isRequired,
  };

  render() {
    const {
      item,
      dictionaries,
      index,
      maxPrice,
      minPrice,
    } = this.props;
    return (
      <div className={styles.itemContent}>
        <FilterTypeSelector
          index={index}
          dictionaries={dictionaries}
          key={index}
          maxPrice={maxPrice}
          minPrice={minPrice}
          item={item}
        />
      </div>
    );
  }
}

export default FilterContent;
