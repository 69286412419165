import React from 'react';
import { Link } from 'react-router-dom';

import Image from '../components/Image';

import styles from '../styles/app.css';

const Dummy = () => (
  <section className={`${styles.dummy}`}>
    <Link to='/'>
      <Image
        img={{ src: '/img/dummy.jpg' }}
      />
    </Link>
  </section>
);

export default Dummy;
