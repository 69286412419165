import React from 'react';
import PropTypes from 'prop-types';

import Logo from './components/Logo';
import SocialList from './components/SocialList';

import styles from './css/styles.css';

const Footer = ({
  facebook,
  twitter,
  google,
  rss,
}) => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <Logo />

      <SocialList
        facebook={facebook}
        twitter={twitter}
        google={google}
        rss={rss}
      />
    </div>
  </footer>
);

Footer.propTypes = {
  facebook: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  google: PropTypes.string.isRequired,
  rss: PropTypes.string.isRequired,
};

export default Footer;
