import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FilterItem from './FilterItem';

import styles from '../css/styles.css';

class FilterList extends Component {
  static propTypes = {
    filtersAttributes: PropTypes.array.isRequired,
    dictionaries: PropTypes.array.isRequired,
    maxPrice: PropTypes.number.isRequired,
    minPrice: PropTypes.number.isRequired,
  };

  state = {
    activeItem: 0,
  };

  setActiveItem = (index) => {
    this.setState({ activeItem: index });
  };


  render() {
    const {
      filtersAttributes,
      dictionaries,
      maxPrice,
      minPrice,
    } = this.props;
    const { activeItem } = this.state;

    if (!filtersAttributes.length) { return null; }

    return (
      <ul className={styles.filterList}>
        {
          filtersAttributes.map((item, index) => {
            return (
              <FilterItem
                onClick={this.setActiveItem}
                activeItem={activeItem}
                maxPrice={maxPrice}
                minPrice={minPrice}
                index={index}
                dictionaries={dictionaries}
                key={index}
                item={item}
              />
            );
          })
        }
      </ul>
    );
  }
}

export default FilterList;
