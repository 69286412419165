import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import map from 'lodash/map';
import cx from 'classnames';

import Image from '../Image';
import BannerArticle from './components/BannerArticle';

import styles from './css/styles.css';

class Banner extends PureComponent {
  static propTypes = {
    data: PropTypes.array.isRequired, // TODO: add data shape;
  };

  constructor(props) {
    super(props);

    const timerId = setInterval(this.next, 5000);

    this.state = {
      timerId, // eslint-disable-line
      activeIndex: 0,
    };
  }

  next = () => {
    const { data } = this.props;
    const { activeIndex } = this.state;

    if (activeIndex === data.length - 1) {
      this.setState({
        activeIndex: 0,
      });
    } else {
      this.setState({
        activeIndex: activeIndex + 1,
      });
    }
  };

  prev = () => {
    const { data } = this.props;
    const { activeIndex } = this.state;

    if (activeIndex === 0) {
      this.setState({
        activeIndex: data.length - 1,
      });
    } else {
      this.setState({
        activeIndex: activeIndex - 1,
      });
    }
  };

  setActiveSlide = (index) => {
    this.setState({
      activeIndex: index,
    });
  };

  clickAndClear = (func) => {
    const { timerId } = this.state;

    if (timerId) {
      clearInterval(timerId);
    }

    func();

    const newTimerId = setInterval(this.next, 5000);

    this.setState({
      timerId: newTimerId,
    });

    setTimeout(newTimerId, 5000);
  };

  render() {
    const { data } = this.props;
    const { activeIndex } = this.state;

    if (!data) {
      return null;
    }

    const sortedData = data.sort((a, b) => (a.position - b.position));

    return (
      <section className={styles.banner}>
        <div>
          <div className={styles.carousel}>
            <button
              className={styles.prev}
              type='button'
              onClick={() => this.clickAndClear(this.prev)}
            />

            <ReactCSSTransitionGroup
              className={styles.imageWrap}
              transitionName="fadeInFadeOut"
              transitionEnterTimeout={0}
              transitionLeaveTimeout={0}
            >
              {
                map(sortedData, (item, index) => {
                  return (
                    <Image
                      className={cx(styles.activeImage, { show: activeIndex === index })}
                      img={item.image}
                      alt={`${item.image.src}`}
                      key={`${item.image.src}`}
                    />
                  );
                })
              }
            </ReactCSSTransitionGroup>

            <button
              className={styles.next}
              type='button'
              onClick={() => this.clickAndClear(this.next)}
            />

            <div className={styles.controlDots}>
              {map(sortedData, (dot, index) => {
                return (
                  <div
                    key={index}
                    className={cx(styles.dot, { selected: activeIndex === index })}
                    onClick={() => this.clickAndClear(() => this.setActiveSlide(index))}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <BannerArticle
          key={activeIndex}
          description={sortedData[activeIndex].description}
          title={sortedData[activeIndex].title}
        />
      </section>
    );
  }
}

export default Banner;
