import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './css/styels.css';

const Input = ({
  className,
  onChange,
  onBlur,
  value,
  placeholder,
  type,
}) => (
  <input
    className={cx(styles.input, className)}
    onChange={onChange}
    onBlur={onBlur}
    value={value}
    type={type}
    placeholder={placeholder}
  />
);

Input.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
};

Input.defaultProps = {
  className: '',
  onBlur: () => {},
  placeholder: '',
};

export default Input;
