exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "body {\n  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;\n}\n\n.headerWrap__1uwEX {\n  position: fixed;\n  background: white;\n  z-index: 1000;\n  width: 100%;\n}\n\n.content__124iA {\n  padding-top: 165px;\n}\n\n.page__18AQu {\n  min-width: 1200px;\n  margin: 0 auto;\n  position: relative;\n  min-height: 100vh;\n  padding-bottom: 370px;\n}\n\n.dummy__1NHXN {\n  position: relative;\n  width: 1200px;\n  margin: 0 auto 20px;\n}", ""]);

// exports
exports.locals = {
	"headerWrap": "headerWrap__1uwEX",
	"content": "content__124iA",
	"page": "page__18AQu",
	"dummy": "dummy__1NHXN"
};