import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import set from 'lodash/set';
import includes from 'lodash/includes';

import { getInitialState } from './state';
import { getComparedProducts } from './selectors';
import { ADD_PRODUCT_TO_COMPARE, COMPARED_PRODUCTS, REMOVE_PRODUCT_FROM_COMPARE } from '../../consts';

const reducer = {};

export const addProductToCompare = (id, categoryId) => ({
  type: ADD_PRODUCT_TO_COMPARE,
  id,
  categoryId,
});

reducer[ADD_PRODUCT_TO_COMPARE] = (state, { id, categoryId }) => {
  if (!id || !categoryId) {
    return state;
  }

  // get all compared products from localStorage
  const comparedProducts = getComparedProducts();
  const comparedProductsById = get(comparedProducts, categoryId, []);

  if (includes(comparedProductsById, id)) return state;

  const newComparedProducts = [...comparedProductsById];

  // check if max length of compared products less than 5
  if (newComparedProducts.length >= 5) return state;

  newComparedProducts.push(id);

  // save id to localStorage
  comparedProducts[categoryId] = newComparedProducts;
  localStorage.setItem(COMPARED_PRODUCTS, JSON.stringify(comparedProducts));

  const newState = { ...state };
  return set(newState, categoryId, newComparedProducts);
};

export const removeProductFromCompare = (id, categoryId) => ({
  type: REMOVE_PRODUCT_FROM_COMPARE,
  id,
  categoryId,
});

reducer[REMOVE_PRODUCT_FROM_COMPARE] = (state, { id, categoryId }) => {
  if (!id || !categoryId) {
    return state;
  }

  // get all compared products from localStorage
  const comparedProducts = getComparedProducts();
  const comparedProductsById = get(comparedProducts, categoryId, []);
  const productIndex = comparedProductsById.indexOf(id);

  if (productIndex === -1) return state;

  const newState = { ...comparedProducts };

  newState[categoryId].splice(productIndex, 1);
  localStorage.setItem(COMPARED_PRODUCTS, JSON.stringify(newState));

  return newState;
};

export const compareReducer = (state = getInitialState(), action = {}) => {
  if (!isFunction(get(reducer, action.type))) {
    return state;
  }

  return reducer[action.type](state, action);
};
