import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './css/styles.css';
import { CHECKBOX, RADIO } from '../../consts';

class Checkbox extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    withImages: PropTypes.bool,
    label: PropTypes.string.isRequired,
    counter: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    counter: '',
    className: '',
    image: {},
    withImages: false,
    type: CHECKBOX,
  };

  onChange = () => {
    const { checked, onChange, id } = this.props;

    onChange({ checked: !checked, _id: id });
  };

  render() {
    const {
      id,
      className,
      checked,
      label,
      counter,
      withImages,
      image,
      type,
    } = this.props;

    return (
      <label
        className={cx(
          styles.checkbox,
          className,
          {
            [styles.withImage]: withImages,
            [styles.radio]: type === RADIO,
          },
        )}
        htmlFor={id}
      >
        <input
          id={id}
          className={styles.input}
          type={type}
          checked={checked}
          onChange={this.onChange}
        />

        {withImages && image.src && <img className={styles.image} src={image.src} alt={label} />}
        {withImages && !image.src && <div className={styles.noImage} />}
        <span className={styles.label}>
          {label}
          {counter ? ` (${counter})` : ''}
        </span>
      </label>
    );
  }
}

export default Checkbox;
