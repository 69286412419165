import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import find from 'lodash/find';
import { Link } from 'react-router-dom';

import styles from '../css/similarProducts.css';
import {
  SHORT_NAME,
  SIMILAR_PRODUCTS,
  VALUE,
  UNIT,
  IMAGES,
  DISCOUNT,
  PRICE,
  COMPARE,
} from '../../../consts';
import Image from '../../../components/Image';

export class SimilarProducts extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    categoryId: PropTypes.string.isRequired,
    categoryHref: PropTypes.string.isRequired,
    comparedProducts: PropTypes.array,
    addProductToCompare: PropTypes.func.isRequired,
  };

  static defaultProps = {
    comparedProducts: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      activeProductIndex: 0,
    };
  }

  componentWillReceiveProps() {
    this.setState({
      activeProductIndex: 0,
    });
  }

  next = () => {
    const { products } = this.props;
    const { activeProductIndex } = this.state;

    if (activeProductIndex !== products.length - 1) {
      this.setState({ activeProductIndex: activeProductIndex + 1 });
    }
  };

  prev = () => {
    const { activeProductIndex } = this.state;

    if (activeProductIndex !== 0) {
      this.setState({ activeProductIndex: activeProductIndex - 1 });
    }
  };

  addProductToCompareFunc = () => {
    const {
      products,
      categoryId,
      addProductToCompare,
    } = this.props;
    const { activeProductIndex } = this.state;
    const currentProduct = products[activeProductIndex];
    const _id = get(currentProduct, '_id', '');

    addProductToCompare(_id, categoryId);
  };

  render() {
    const {
      products,
      categoryId,
      categoryHref,
      comparedProducts,
    } = this.props;
    const { activeProductIndex } = this.state;

    if (!products || isEmpty(products)) return null;

    const currentProduct = products[activeProductIndex];

    if (!currentProduct.attributes) return null;

    const name = get(currentProduct, SHORT_NAME, '');
    const price = get(currentProduct.attributes, [DISCOUNT, VALUE], 0) || get(currentProduct.attributes, [PRICE, VALUE], 0);
    const roundedPrice = price.toFixed(2);
    const unit = get(currentProduct.attributes, [UNIT, VALUE], '');
    const images = get(currentProduct, IMAGES, []);
    const image = find(images, (item) => item.position === 0) || images[0];
    const _id = get(currentProduct, '_id', '');
    const seoURL = get(currentProduct, 'seoURL', null);
    const productLink = `/product/${seoURL || _id}`;
    const disableCompare = comparedProducts.includes(_id) || comparedProducts.length >= 5;

    return (
      <section className={styles.wrap}>
        <h2 className={styles.title}>
          {SIMILAR_PRODUCTS}
        </h2>

        <div className={styles.sliderWrap}>
          <div className={styles.slider}>
            <div
              onClick={this.prev}
              className={styles.prev}
            />

            <div className={styles.imgWrap}>
              <Image className={styles.productImg} img={image} />
              <div className={styles.buttonsWrap}>
                <Link to={{ pathname: productLink, state: { categoryId, categoryHref } }} className={styles.navLink}>
                  <button type='button' className={styles.button}>описание</button>
                </Link>

                <button
                  type='button'
                  className={cx(styles.button, { [styles.disable]: disableCompare })}
                  onClick={this.addProductToCompareFunc}
                >
                  {COMPARE}
                </button>
              </div>
            </div>

            <div
              onClick={this.next}
              className={styles.next}
            />
          </div>

          <Link to={{ pathname: productLink, state: { categoryId, categoryHref } }} className={styles.innerWrap}>
            <div className={styles.productName}>
              {name}
            </div>

            <div className={styles.productPrice}>
              <span>
                {roundedPrice}
              </span>
              &nbsp;грн/
              {unit}
            </div>
          </Link>
        </div>

        <div className={styles.separator} />
      </section>
    );
  }
}

export default SimilarProducts;
