import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import { parseQueryString, setMultipleFilter } from '../Filter/helpers/setAndParseQueryString';

import styles from './css/styles.css';

class CheckboxColor extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    colorsId: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    bgColor: PropTypes.string,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    bgColor: '',
    className: null,
  };

  onChange = (ev) => {
    ev.preventDefault();

    const {
      location,
      colorsId,
      id,
      match,
      history,
    } = this.props;

    setMultipleFilter(
      {
        location,
        match,
        history,
        _id: colorsId,
        value: id,
      },
    );
  };

  render() {
    const {
      id,
      className,
      label,
      colorsId,
      bgColor,
      location,
    } = this.props;
    let checked = false;
    if (location.search.length !== 0) {
      const parsedSearch = parseQueryString({ string: location.search });
      checked = parsedSearch
        && parsedSearch.filters
        && parsedSearch.filters[colorsId]
        && Array.isArray(parsedSearch.filters[colorsId])
        && parsedSearch.filters[colorsId].includes(id);
    }
    return (
      <label
        className={cx(styles.color, className)}
        htmlFor={id}
      >
        <input
          id={id}
          className={styles.input}
          type='checkbox'
          checked={!!checked}
          onChange={this.onChange}
        />

        <div className={styles.figure} style={{ background: bgColor }} />

        <span className={styles.label}>{label}</span>
      </label>
    );
  }
}

export default withRouter(CheckboxColor);
