exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".submit__3HkmF {\n  position: absolute;\n  top: 10px;\n  right: 1px;\n  cursor: pointer;\n  width: 10px;\n  height: 10px;\n  border: 0;\n  outline: 0;\n  background: transparent;\n  border-color: #b3b3b3;\n  border-top: 2px solid;\n  border-right: 2px solid;\n  -webkit-transform: rotate(45deg);\n          transform: rotate(45deg);\n}\n.wrap__3s-QK {\n  position: relative;\n}", ""]);

// exports
exports.locals = {
	"submit": "submit__3HkmF",
	"wrap": "wrap__3s-QK"
};