export const AMOUNT = 'amount';
export const CATEGORY_HREF = 'categoryHref';
export const CATEGORY_PATH = 'categoryPath';
export const DISCOUNT = 'discount';
export const CATEGORY_ID = 'categoryId';
export const CHECKBOX = 'checkbox';
export const COMPARED_PRODUCTS = 'comparedProducts';
export const RADIO = 'radio';
export const REMOVE_PRODUCT_FROM_COMPARE = 'removeProductFromCompare';
export const IMAGES = 'images';
export const ID = '_id';
export const LABEL = 'label';
export const OTHER_COLORS_VAR = 'otherProductColors';
export const PRICE = 'price';
export const PRODUCTS_DATA = 'productsData';
export const SRC = 'src';
export const SHORT_NAME = 'shortName';
export const SIMILAR_PRODUCTS_VAR = 'similarProducts';
export const ADD_PRODUCT_TO_COMPARE = 'addProductToCompare';
export const TYPE = 'type';
export const VALUE = 'value';
export const NAME = 'name';
export const UNIT = 'unit';

export const FILTERS = 'filters';
// Variables which starts with capital
export const AMOUNT_C = 'Amount';
export const BOOLEAN_C = 'Boolean';
export const COMPLICATED_C = 'Complicated';
export const COLOR_C = 'Color';
export const DISCOUNT_C = 'Discount';
export const INTEGER_C = 'Integer';
export const FLOAT_C = 'Float';
export const PRICE_C = 'Price';
export const LIST_C = 'List';

// RU constants
export const ABOUT_PRODUCT = 'Об изделии';
export const ADDITIONAL = 'Дополнительно';
export const AVAILABLE = 'Есть в наличии';
export const BACK_TO_CATALOGUE = 'в каталог';
export const COMPARE = 'Сравнить';
export const NOT_AVAILABLE = 'Нет в наличии';
export const SIMILAR_PRODUCTS = 'Похожие товары';
export const OTHER_COLORS = 'Другие цвета в этой колекции';
export const PRODUCTS_COMPARE = 'Сравнение товаров';
export const VIEWED = 'Просмотренные';
export const PRODUCT_PAGE_SLIDER_TEXT = 'Цвет и фактура могут отличаться от изображения на экране';

export const SET_VIEWED_PRODUCTS = 'setViewedProducts';
export const VIEWED_PRODUCTS = 'viewedProducts';
