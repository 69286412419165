export const productPageConfig = {
  55000: {
    leftBlock: {
      rangeAttrs: ['durability', 'softness', 'easycleanvalue'],
      picsAttrs: ['pattern', 'easyclean', 'pets'],
    },
    rightBlock: {
      name: 'size'
    },
    others: [
      {
        name: 'material',
      },
      {
        label: 'Сфера применения',
        values: [{ name: 'forhouse' }, { name: 'foroffice' }, { name: 'forxxhibition' }, { name: 'forhotel' }],
      },
      {
        name: 'tint',
        label: 'Цвет',
      },
      {
        name: 'surface',
        label: 'Поверхность / тип ворса',
      },
      {
        name: 'pileheight',
      },
      {
        name: 'totalheight',
      },
      {
        name: 'backing',
      },
      {
        name: 'manufacturetype',
      },
      {
        name: 'class',
      },
      {
        name: 'size',
      },
      {
        name: 'country',
      },
    ],
    compare: [
      {
        name: 'material',
      },
      {
        label: 'Сфера применения',
        values: [{ name: 'forhouse' }, { name: 'foroffice' }, { name: 'forxxhibition' }, { name: 'forhotel' }],
      },
      {
        label: 'Образ жизни',
        values: [{ name: 'pets' }, { name: 'natural' }, { name: 'children' }, { name: 'intensive' }],
        images: true,
      },
      {
        label: 'Поверхность',
        values: [{ name: 'texture' }, { name: 'mat' }, { name: 'loop' }, { name: 'twist' }],
        images: true,
      },
      {
        name: 'tint',
        label: 'Цвет',
      },
      {
        name: 'surface',
        label: 'Поверхность / тип ворса',
      },
      {
        name: 'pileheight',
      },
      {
        name: 'totalheight',
      },
      {
        name: 'backing',
      },
      {
        name: 'manufacturetype',
      },
      {
        name: 'class',
      },
      {
        name: 'size',
      },
      {
        name: 'country',
      },
    ],
  },
  57000: {
    leftBlock: {
      rangeAttrs: ['durability', 'softness', 'easycleanvalue'],
      picsAttrs: ['pattern', 'easyclean', 'pets'],
    },
    rightBlock: {
      name: 'size',
      label: 'Размер',
    },
    others: [
      {
        name: 'material',
      },
      {
        label: 'Сфера применения',
        values: [{ name: 'forhouse' }, { name: 'foroffice' }, { name: 'forxxhibition' }, { name: 'forhotel' }],
      },
      {
        name: 'tint',
        label: 'Цвет',
      },
      {
        name: 'surface',
        label: 'Поверхность / тип ворса',
      },
      {
        name: 'pileheight',
      },
      {
        name: 'totalheight',
      },
      {
        name: 'backing',
      },
      {
        name: 'manufacturetype',
      },
      {
        name: 'class',
      },
      {
        label: 'Размер',
        name: 'size',
      },
      {
        name: 'country',
      },
    ],
    compare: [
      {
        name: 'material',
      },
      {
        label: 'Сфера применения',
        values: [{ name: 'forhouse' }, { name: 'foroffice' }, { name: 'forxxhibition' }, { name: 'forhotel' }],
      },
      {
        label: 'Образ жизни',
        values: [{ name: 'pets' }, { name: 'natural' }, { name: 'children' }, { name: 'intensive' }],
        images: true,
      },
      {
        label: 'Поверхность',
        values: [{ name: 'texture' }, { name: 'mat' }, { name: 'loop' }, { name: 'twist' }],
        images: true,
      },
      {
        name: 'tint',
        label: 'Цвет',
      },
      {
        name: 'surface',
        label: 'Поверхность / тип ворса',
      },
      {
        name: 'pileheight',
      },
      {
        name: 'totalheight',
      },
      {
        name: 'backing',
      },
      {
        name: 'manufacturetype',
      },
      {
        name: 'class',
      },
      {
        label: 'Размер',
        name: 'size',
      },
      {
        name: 'country',
      },
    ],
  },
  55002: {
    leftBlock: {
      rangeAttrs: ['durability', 'softness', 'easycleanvalue'],
      picsAttrs: ['pattern', 'easyclean', 'pets'],
    },
    rightBlock: {
      name: 'size',
      label: 'Ширина рулона',
    },
    others: [
      {
        name: 'material',
      },
      {
        name: 'pileheight',
      },
      {
        name: 'totalheight',
      },
      {
        name: 'backing',
      },
      {
        name: 'size',
        label: 'Ширина рулона',
      },
      {
        name: 'country',
      },
    ],
    compare: [
      {
        label: 'Ширина рулона',
        name: 'size',
      },
      {
        name: 'material',
      },
      {
        name: 'country',
      },
    ],
  },
  72000: {
    leftBlock: {
      rangeAttrs: [],
      picsAttrs: [],
    },
    rightBlock: {
      name: 'size',
      label: 'Размер',
    },
    others: [
      {
        name: 'size',
        label: 'Размер',
      },
      {
        name: 'tint',
        label: 'Оттенок',
      },
    ],
    compare: [
      {
        name: 'size',
        label: 'Размер',
      },
      {
        name: 'tint',
        label: 'Оттенок',
      },
    ],
  },
};
