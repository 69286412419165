exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".footer__3UXSo {\n  height: 90px;\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  background: #262626;\n  color: #b2b2b2;\n}\n\n.container__1NTGC {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  max-width: 1000px;\n  height: 100%;\n  margin: 0 auto;\n}\n\n.logo__3G-PL {\n  height: 50px;\n}\n\n.logo__3G-PL img {\n  height: 100%;\n}\n\n.social__1vS5l {\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n\n.socialItem__1612O {\n  margin: 0 40px 0 0;\n}\n\n.socialItem__1612O:last-child {\n  margin: 0;\n}", ""]);

// exports
exports.locals = {
	"footer": "footer__3UXSo",
	"container": "container__1NTGC",
	"logo": "logo__3G-PL",
	"social": "social__1vS5l",
	"socialItem": "socialItem__1612O"
};