exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".wrap__2EEz_ {\n  padding: 55px 110px 50px 63px;\n}\n\n.title__3ovJ7 {\n  font-size: 13px;\n  text-transform: uppercase;\n  margin-bottom: 24px;\n}\n\n.text__3a7ec {\n  font-size: 13px;\n  line-height: 20px;\n}", ""]);

// exports
exports.locals = {
	"wrap": "wrap__2EEz_",
	"title": "title__3ovJ7",
	"text": "text__3a7ec"
};