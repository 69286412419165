exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".media__47Wov {\n  position: relative;\n  /*display: inline-block;*/\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n  width: 100%;\n}\n\n.holder__ayyGD,\n.mask__2dLmM,\n.image__2-_cm {\n  display: block;\n  pointer-events: none;\n  -webkit-user-select: none;\n     -moz-user-select: none;\n      -ms-user-select: none;\n          user-select: none;\n}\n\n.image__2-_cm {\n  width: 100%;\n}\n\n.mask__2dLmM {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}", ""]);

// exports
exports.locals = {
	"media": "media__47Wov",
	"holder": "holder__ayyGD",
	"mask": "mask__2dLmM",
	"image": "image__2-_cm"
};