exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".wrap__1xgdC {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  color: #404040;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  font-size: 10px;\n  position: relative;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n}\n.activeItem__3OoE9 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin-bottom: 10px;\n  margin-right: 10px;\n  padding-left: 10px;\n  padding-right: 30px;\n  border: 1px solid #e7e7e7;\n  position: relative;\n  height: 20px;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.activeItem__3OoE9 span {\n  cursor: pointer;\n}\n.activeItem__3OoE9:hover {\n  color: rgba(255, 255, 255, .87);\n  background-color: #2e2e2e;\n}\n.deleteButton__2Q3GV {\n  height: 100%;\n  margin-left: 5px;\n  cursor: pointer;\n  position: absolute;\n  color: inherit;\n  background-color: transparent;\n  border: none;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  right: 0;\n  top: 0;\n  font-size: 12px;\n  width: 20px;\n  line-height: 12px;\n}\n.deleteButton__2Q3GV {\n  outline: none;\n}\n.deleteButton__2Q3GV:before, .deleteButton__2Q3GV:after {\n  width: 8px;\n  height: 1px;\n  content: '';\n  left: calc(50% - 4px);\n  position: absolute;\n  top: 50%;\n  background-color: #2e2e2e;\n  -webkit-transform-origin: center;\n          transform-origin: center;\n}\n.deleteButton__2Q3GV:before {\n  -webkit-transform: rotate(45deg);\n          transform: rotate(45deg);\n}\n.deleteButton__2Q3GV:after {\n  -webkit-transform: rotate(-45deg);\n          transform: rotate(-45deg);\n}\n.activeItem__3OoE9:hover .deleteButton__2Q3GV:before {\n  background-color: rgba(255, 255, 255, .87);;\n}\n.activeItem__3OoE9:hover .deleteButton__2Q3GV:after {\n  background-color: rgba(255, 255, 255, .87);\n}", ""]);

// exports
exports.locals = {
	"wrap": "wrap__1xgdC",
	"activeItem": "activeItem__3OoE9",
	"deleteButton": "deleteButton__2Q3GV"
};