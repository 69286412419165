import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import cx from 'classnames';
import SortLink from './SortLink';
import styles from '../css/products.css';


const ProductsLinks = (
  {
    match,
    location,
    productsLength,
    uploadedProductsLength,
  },
) => {
  const parseSearch = queryString.parse(location.search);
  const isSwatch = !parseSearch.view || parseSearch.view === 'swatch';
  const pageNumber = parseSearch.page || 1;
  let newPage = pageNumber;
  let count = isSwatch ? 15 : 50;

  const maxPage = (productsLength / (count * 2));
  const isShowMore = pageNumber <= maxPage;

  if (isShowMore) {
    newPage = Number(pageNumber) + 0.5;
  }

  const showMoreProducts = productsLength - uploadedProductsLength;

  if (count > showMoreProducts) {
    count = showMoreProducts;
  }

  const link = {
    name: () => (
      <span>
        {'Подгрузить еще '}
        <span className={styles.count}>{count}</span>
        {' вариантов из '}
        <span className={styles.count}>{showMoreProducts}</span>
      </span>),
    type: newPage,
    field: 'page',
  };

  if (showMoreProducts === 0) {
    return null;
  }

  return (
    <div className={styles.buttonsWrap}>
      <SortLink
        className={cx(styles.showMore, { [styles.disable]: !isShowMore })}
        location={location}
        match={match}
        link={link}
      />
    </div>
  );
};

ProductsLinks.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  productsLength: PropTypes.number.isRequired,
  uploadedProductsLength: PropTypes.number.isRequired,
};

export default ProductsLinks;
