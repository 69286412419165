exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".wrap__3U8wv {\n  padding: 55px 0 50px 31px;\n  color: #262626;\n}\n\n.title__qJt4P {\n  font-size: 13px;\n  text-transform: uppercase;\n  margin-bottom: 25px;\n}\n\n.attributes__wB6Eq {\n  list-style: none;\n  font-size: 13px;\n  line-height: 20px;\n}", ""]);

// exports
exports.locals = {
	"wrap": "wrap__3U8wv",
	"title": "title__qJt4P",
	"attributes": "attributes__wB6Eq"
};