import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';

import styles from '../css/otherAttrs.css';
import { productPageConfig } from '../config';
import {
  ADDITIONAL,
  LABEL,
  NAME,
  VALUE,
} from '../../../consts';


const OtherAttributes = ({ attributes, categoryId }) => {
  if (!categoryId) return null;

  const order = productPageConfig[categoryId] && productPageConfig[categoryId].others;

  if (!attributes || !order) return null;

  return (
    <section className={styles.wrap}>
      <h2 className={styles.title}>
        {ADDITIONAL}
      </h2>

      <ul className={styles.attributes}>
        {map(order, (item, index) => {
          const itemName = get(item, NAME, null);

          const attribute = get(attributes, itemName, null);
          const attributeLabel = get(attribute, LABEL, '');
          const label = get(item, LABEL, attributeLabel);
          let value = get(attribute, VALUE, null);

          if (item.values) {
            value = item.values
              .map((element) => {
                return get(attributes[element.name], LABEL, null);
              })
              .filter((element) => element !== null)
              .join(', ');
          }

          if (!label || !value) return null;

          return (
            <li key={index}>
              {label}
              :&nbsp;
              {value}
            </li>
          );
        })}
      </ul>
    </section>
  );
};

OtherAttributes.propTypes = {
  attributes: PropTypes.object.isRequired,
  categoryId: PropTypes.string.isRequired,
};

export default OtherAttributes;
