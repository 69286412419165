import { createStore, compose } from 'redux';
import createReducer from './reducers';

export const configureStore = (initialState = {}) => {
  const composeEnhancers = process.env.NODE_ENV === 'development'
  && typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

  return createStore(
    createReducer(),
    initialState,
    composeEnhancers(),
  );
};
