import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Paragraph from './Paragraph';

import styles from '../css/styles.css';

const Description = ({ title, subtitle, text }) => (
  <div className={styles.description}>
    <Header
      title={title}
      subtitle={subtitle}
    />

    <Paragraph text={text} />
  </div>
);

Description.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Description;
