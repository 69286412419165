import minimist from 'minimist';

const isDev = process.env.NODE_ENV !== 'production';

export const argv = minimist(process.argv.slice(2));
export const host = window.location.hostname || 'localhost';
export const port = argv.port || process.env.PORT || 3001;
export const API_URL = `http://${host}${isDev ? `:${port}` : ''}/api/data`;
export const GET_OPTIONS = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};
export const GOOGLE_API_KEY = 'AIzaSyDOB0CqM-xBh50W5PGX6nXZqNCPt8Yupqo';

export default {
  API_URL,
  GET_OPTIONS,
  GOOGLE_API_KEY,
};
