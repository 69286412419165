import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import cx from 'classnames';

import { getComparedProductsByCategoryId } from '../selectors';
import { postApi } from '../../../../utils/getApi';
import {
  IMAGES,
  PRODUCTS_DATA,
  SHORT_NAME,
  ID,
  COMPARE,
} from '../../../consts';
import styles from '../css/compare.css';
import { removeProductFromCompare as removeProductFromCompareAction } from '../actions';


class CompareLine extends Component {
  static propTypes = {
    style: PropTypes.string,
    comparedProducts: PropTypes.array,
    removeProductFromCompare: PropTypes.func.isRequired,
    categoryId: PropTypes.string,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    categoryHref: PropTypes.string.isRequired,
  };

  static defaultProps = {
    style: {},
    comparedProducts: [],
    categoryId: '55000',
  };

  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };

    this.getComparedProductsData(props.comparedProducts);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.comparedProducts, this.props.comparedProducts)) {
      this.getComparedProductsData(nextProps.comparedProducts);
    }
  }

  getComparedProductsData = (products) => {
    if (products.length === 0) return;

    postApi(PRODUCTS_DATA, { body: { products } })
      .then((data) => {
        if (!data) return;

        this.setState({
          data,
        });
      })
      .catch((error) => console.log('Error on category page fetch', error)); // eslint-disable-line
  };

  compareLineItems = (data) => {
    const comparedItems = [];

    for (let i = 0; i < 5; i++) {
      if (data[i]) {
        comparedItems.push(data[i]);
      } else {
        comparedItems.push(null);
      }
    }

    return comparedItems;
  };

  toCompare = (ev) => {
    ev.preventDefault();

    const {
      history,
      categoryId,
      location,
      comparedProducts,
      categoryHref,
    } = this.props;

    if (comparedProducts.length < 2) { return; }

    const compareLink = `/compare/${categoryId}`;
    const comparePath = { pathname: compareLink, state: { search: location.search, categoryId, categoryHref } };
    history.push(comparePath);
  };

  render() {
    const {
      style,
      comparedProducts,
      removeProductFromCompare,
      categoryId,
    } = this.props;
    const { data } = this.state;

    if (!data || comparedProducts.length === 0) return null;

    const comparedItems = this.compareLineItems(comparedProducts);
    const disableCompare = comparedProducts.length < 2;

    return (
      <div className={cx(style, styles.compareLine)}>
        {map(comparedItems, (item, index) => {
          if (item) {
            const product = find(data, (dataItem) => get(dataItem, ID) === item);

            if (!product) return null;

            const images = get(product, IMAGES);
            const shortName = get(product, SHORT_NAME);
            const sortedImgs = sortBy(images, (image) => image.position);

            return (
              <div
                key={index}
                className={styles.compareLineItem}
              >
                <img src={sortedImgs[0].src} alt={shortName} />

                <div
                  className={styles.removeIcon}
                  onClick={() => removeProductFromCompare(item, categoryId)}
                />
              </div>
            );
          }

          return (
            <div key={index} className={styles.compareLineItem} />
          );
        })}

        <button
          type='button'
          className={cx(styles.compareBtn, { [styles.disable]: disableCompare })}
          onClick={this.toCompare}
        >
          {COMPARE}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { categoryId } = props;

  return {
    comparedProducts: getComparedProductsByCategoryId(categoryId),
  };
};

export default withRouter(connect(mapStateToProps, {
  removeProductFromCompare: removeProductFromCompareAction,
})(CompareLine));
