import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FilterList from './components/FilterList';

class Filter extends Component {
  static propTypes = {
    filtersAttributes: PropTypes.array.isRequired,
    dictionaries: PropTypes.array.isRequired,
    maxPrice: PropTypes.number.isRequired,
    minPrice: PropTypes.number.isRequired,
  };

  render() {
    const {
      filtersAttributes,
      dictionaries,
      maxPrice,
      minPrice,
    } = this.props;

    return (
      <div>
        <FilterList
          maxPrice={maxPrice}
          minPrice={minPrice}
          filtersAttributes={filtersAttributes}
          dictionaries={dictionaries}
        />
      </div>
    );
  }
}

export default Filter;
