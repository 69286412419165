import React from 'react';
import PropTypes from 'prop-types';
import { parseQueryString } from '../helpers/setAndParseQueryString';

import styles from '../css/activeFilters.css';

const createHandler = ({
  location,
  match,
  history,
  value,
  _id,
  deleteHandler,
}) => () => {
  const parsedSearch = parseQueryString({ string: location.search });
  const parsedSearchFilters = parsedSearch.filters;
  deleteHandler({
    parsedSearch,
    parsedSearchFilters,
    match,
    history,
    value,
    _id,
  });
};

const ActiveFilterItem = (
  {
    location,
    match,
    history,
    value,
    label,
    _id,
    deleteHandler,
  },
) => {
  return (
    <div className={styles.activeItem}>
      <span dangerouslySetInnerHTML={{ __html: label }} />
      <button
        type='button'
        onClick={createHandler({
          location,
          match,
          history,
          value,
          _id,
          deleteHandler,
        })}
        className={styles.deleteButton}
      />
    </div>
  );
};

ActiveFilterItem.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
  deleteHandler: PropTypes.func.isRequired,
};

export default ActiveFilterItem;
