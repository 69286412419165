import React from 'react';
import PropTypes from 'prop-types';
import styles from '../css/sortPanelLinks.css';

const GridIcon = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    className={styles.icon}
  >
    <defs>
      <path
        id="jcwpa"
        d="M1189.51 570.54c-.8 0-1.46-.66-1.46-1.46V563c0-.8.65-1.46 1.46-1.46h6.1c.8 0 1.46.65 1.46 1.46v6.08c0 .8-.66 1.46-1.47 1.46zm11 0c-.81 0-1.47-.66-1.47-1.46V563c0-.8.66-1.46 1.46-1.46h6.1c.8 0 1.46.65 1.46 1.46v6.08c0 .8-.66 1.46-1.47 1.46zm-11 10.98c-.8 0-1.46-.65-1.46-1.45v-6.08c0-.8.65-1.46 1.46-1.46h6.1c.8 0 1.46.65 1.46 1.46v6.08c0 .8-.66 1.45-1.47 1.45zm11 0c-.81 0-1.47-.65-1.47-1.45v-6.08c0-.8.66-1.46 1.46-1.46h6.1c.8 0 1.46.65 1.46 1.46v6.08c0 .8-.66 1.45-1.47 1.45z"
      />
    </defs>
    <g>
      <g transform="translate(-1188 -561)">
        <use
          fill={isActive ? '#2d2d2d' : '#cfcfcf'}
          xlinkHref="#jcwpa"
          className={styles.gridIcon}
        />
      </g>
    </g>
  </svg>
);

GridIcon.propTypes = {
  isActive: PropTypes.bool,
};

GridIcon.defaultProps = {
  isActive: true,
};

export default GridIcon;
