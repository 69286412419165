/* eslint-disable */
import React from 'react';

import styles from '../css/styles.css';

const Logo = () => (
  <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 300.3 99.2'>
    <path className={styles.st0} d='M276.5,44.4l-26.8,20.1C258.8,58,267.8,51.3,276.5,44.4z'
    />
    <path className={styles.st1} d='M74.9,47.8H40.4C51.7,48,63.2,48,74.9,47.8z' />
    <path d='M282.9,0h-0.4v24.9L248.8,0l-11.6,0.1c-4.2,0-8.3-0.1-12.5-0.1c-0.3,0-0.5,0-0.8,0c-6.1,0-12.1,0-18.2,0 c-18.4,0-36.8,0-55.2,0c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5,0-0.8,0c-24.5,0-48.9,0-73.4,0c-0.2,0-0.5,0-0.8,0c-0.2,0-0.5,0-0.8,0 C49.4,0,24.7,0,0,0c0,0.3,0,0.5,0,0.8c0,5.4,0,10.7,0,16c0,0.2,0,0.5,0,0.8c0,0.2,0,0.5,0,0.8c0,9.3,0,18.7,0,28c0,0.3,0,0.5,0,0.8 c0,0.3,0,0.5,0,0.8C0,52.9,0,58,0,63.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.5,0,0.7c0.8,0,1.7,0,2.5,0c25.2,0.1,49.4,0.1,72.4,0.1 c0.3,0,0.5,0,0.8,0c0-0.3,0-0.5,0-0.8c0-5.4,0-10.7,0-16c-0.2,0-0.5,0-0.8,0c-11.7,0.2-23.2,0.2-34.4,0H18.2V47c0-0.2,0-0.5,0-0.8 c0-9.3,0-18.7,0-28c18.6,0,37.3,0,55.9,0c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c6.1,0,12.1,0,18.2,0c0.3,0,0.5,0,0.8,0 c0.3,0,0.5,0,0.8,0c0,15.1,0,30.3,0,45.5c0,0.2,0,0.5,0,0.8c5.6,0,11.1,0,16.7,0c0.3,0,0.5,0,0.8,0c0-0.3,0-0.5,0-0.8 c0-15.2,0-30.4,0-45.5c13.2,0,25.3,0,36.3,0c0.2,0,0.5,0,0.8,0c0.2,0,0.5,0,0.8,0c18.5,0,37-0.1,55.6-0.2h19.3v-0.6 c0-0.3,0-0.5,0-0.8c0-3.9,0-7.7,0-11.6c0-1.2,0-2.4,0-3.6l29.8,21.9l11.8,8.5l-34.3,26.7l-6.9,5.5l0.3,0.4c3.3,0,6.5,0,9.8,0 c0.2,0,0.5,0,0.8,0c4,0,8,0,12.1,0c0.2,0,0.5,0,0.8,0l26.8-20.1l6.1-4.5v24.6c6,0,11.4,0,16.3,0c0.2,0,0.7,0,1.5,0 c0-0.3,0-0.5,0-0.8l-0.8-63c0-0.2,0-0.5,0-0.8c-0.2,0-0.5,0-0.8,0C293.5,0,288.2,0,282.9,0z'
    />
    <path d='M223.9,64.5c0.2,0,0.5,0,0.8,0h0.8V47.4C200.2,47.2,175,47,149.8,47c0,5.8,0,10,0,12.7c0,2.9,0,4.5,0,4.8 C174.5,64.5,199.2,64.5,223.9,64.5z'
    />
    <path className={styles.st0} d='M225.5,64.5l0.5-0.4l6.9-5.5c-2.1,1.6-4.2,3.1-6.4,4.6C226.3,63.3,225.9,63.7,225.5,64.5z'
    />
    <path d='M225.5,24.3v-0.8l-18.9,0.1c-18.9-0.1-37.9-0.1-56.7-0.1c0,0.2,0,0.5,0,0.8c0,5.5,0,11.1,0,16.7c0,0.3,0,0.5,0,0.8 c24.7,0,49.4,0,74.1,0c0.2,0,0.5,0,0.8,0c0.2,0,0.5,0,0.8,0c0-0.2,0-0.5,0-0.8C225.5,35.4,225.5,29.8,225.5,24.3z'
    />
    <path id='XMLID_391_' className={styles.st2} d='M116.1,37.8c0.5,0,1.6,0.2,1.6-0.6c0-0.5-1.3-0.4-1.6-0.4c-0.7,0-0.5,0-0.5-0.6 c0-0.4-0.7-0.3-0.7-0.6c-0.1-0.2,0-0.8,0-1.1c0-0.5,0.5-0.7,1-0.5c0.3,0.1,0.3,1.3,1,0.9c0.1,0,0.5-0.4,0.6-0.5 c0.1-0.1,1.1-0.5,1.3-0.4c0.9,0.4-0.2,0.7-0.3,0.9c-0.2,0.3-3,0.3-2.1,1.3c0.2,0.2,1.6,0,1.9,0c0.5,0,0.6,0.4,0.6,0.9 c0,0.9-0.9,1.6-1.8,1.6c-0.4,0-0.8,0-1-0.2C115.8,38.4,115.8,37.8,116.1,37.8L116.1,37.8z'
    />
    <g id='XMLID_392_'>
        <path id='XMLID_393_' d='M176.9,69.6c4.3,0,7.8,1.4,10.7,4.3s4.3,6.4,4.3,10.5c0,4.1-1.4,7.6-4.3,10.5c-2.9,2.9-6.4,4.3-10.5,4.3 c-4.2,0-7.7-1.4-10.6-4.3c-2.9-2.9-4.3-6.3-4.3-10.4c0-2.7,0.7-5.2,2-7.5s3.1-4.1,5.4-5.4C171.8,70.3,174.3,69.6,176.9,69.6z M177,72.3c-2.1,0-4.1,0.5-5.9,1.6c-1.9,1.1-3.3,2.5-4.4,4.4c-1,1.8-1.6,3.9-1.6,6.2c0,3.3,1.2,6.2,3.5,8.5 c2.3,2.3,5.1,3.5,8.4,3.5c2.2,0,4.2-0.5,6.1-1.6c1.9-1.1,3.3-2.5,4.4-4.4c1-1.8,1.6-3.9,1.6-6.1c0-2.2-0.5-4.3-1.6-6.1 c-1-1.8-2.5-3.3-4.4-4.3C181.2,72.9,179.2,72.3,177,72.3z'
        />
        <path id='XMLID_396_' d='M197.6,98.5V70.3h0.6L217,91.9V70.3h2.8v28.2h-0.6l-18.6-21.3v21.3H197.6z'
        />
        <path id='XMLID_398_' d='M226.6,70.3h2.8v25.5h10.8v2.7h-13.7V70.3z' />
        <path id='XMLID_400_' d='M244.3,70.3h2.8v28.2h-2.8V70.3z' />
        <path id='XMLID_402_' d='M253.6,98.5V70.3h0.6L273,91.9V70.3h2.8v28.2h-0.6l-18.6-21.3v21.3H253.6z'
        />
        <path id='XMLID_404_' d='M282.6,70.3h16.1v2.8h-13.3v8.8h13.2v2.8h-13.2v11.1h13.2v2.8h-16V70.3z'
        />
    </g>
    <path id='XMLID_406_' className={styles.st2} d='M119.9,37.9c0.5,0,1.6,0.2,1.6-0.6c0-0.5-1.3-0.4-1.6-0.4c-0.7,0-0.5,0-0.5-0.6	c0-0.4-0.7-0.3-0.7-0.6c-0.1-0.2,0-0.8,0-1.1c0-0.5,0.5-0.7,1-0.5c0.3,0.1,0.3,1.3,1,0.9c0.1,0,0.5-0.4,0.6-0.5	c0.1-0.1,1.1-0.5,1.3-0.4c0.9,0.4-0.2,0.7-0.3,0.9c-0.2,0.3-3,0.3-2.1,1.3c0.2,0.2,1.6,0,1.9,0c0.5,0,0.6,0.4,0.6,0.9	c0,0.9-0.9,1.6-1.8,1.6c-0.4,0-0.8,0-1-0.2C119.6,38.4,119.6,37.8,119.9,37.9L119.9,37.9z'
    />
    <path id='XMLID_407_' className={styles.st2} d='M121.4,40.2c0.5,0,1.6,0.2,1.6-0.6c0-0.5-1.3-0.4-1.6-0.4c-0.7,0-0.5,0-0.5-0.6	c0-0.4-0.7-0.3-0.7-0.6c-0.1-0.2,0-0.8,0-1.1c0-0.5,0.5-0.7,1-0.5c0.3,0.1,0.3,1.3,1,0.9c0.1,0,0.5-0.4,0.6-0.5	c0.1-0.1,1.1-0.5,1.3-0.4c0.9,0.4-0.2,0.7-0.3,0.9c-0.2,0.3-3,0.3-2.1,1.3c0.2,0.2,1.6,0,1.9,0c0.5,0,0.6,0.4,0.6,0.9	c0,0.9-0.9,1.6-1.8,1.6c-0.4,0-0.8,0-1-0.2C121.1,40.7,121.1,40.1,121.4,40.2L121.4,40.2z'
    />
    <path id='XMLID_408_' className={styles.st2} d='M116.1,38.4c0.5,0,1.6,0.2,1.6-0.6c0-0.5-1.3-0.4-1.6-0.4c-0.7,0-0.5,0-0.5-0.6	c0-0.4-0.7-0.3-0.7-0.6c-0.1-0.2,0-0.8,0-1.1c0-0.5,0.5-0.7,1-0.5c0.3,0.1,0.3,1.3,1,0.9c0.1,0,0.5-0.4,0.6-0.5	c0.1-0.1,1.1-0.5,1.3-0.4c0.9,0.4-0.2,0.7-0.3,0.9c-0.2,0.3-3,0.3-2.1,1.3c0.2,0.2,1.6,0,1.9,0c0.5,0,0.6,0.4,0.6,0.9	c0,0.9-0.9,1.6-1.8,1.6c-0.4,0-0.8,0-1-0.2C115.8,39,115.8,38.4,116.1,38.4L116.1,38.4z'
    />
    <path id='XMLID_409_' className={styles.st2} d='M119,41c0.5,0,1.6,0.2,1.6-0.6c0-0.5-1.3-0.4-1.6-0.4c-0.7,0-0.5,0-0.5-0.6	c0-0.4-0.7-0.3-0.7-0.6c-0.1-0.2,0-0.8,0-1.1c0-0.5,0.5-0.7,1-0.5c0.3,0.1,0.3,1.3,1,0.9c0.1,0,0.5-0.4,0.6-0.5	c0.1-0.1,1.1-0.5,1.3-0.4c0.9,0.4-0.2,0.7-0.3,0.9c-0.2,0.3-3,0.3-2.1,1.3c0.2,0.2,1.6,0,1.9,0c0.5,0,0.6,0.4,0.6,0.9	c0,0.9-0.9,1.6-1.8,1.6c-0.4,0-0.8,0-1-0.2C118.7,41.6,118.7,41,119,41L119,41z'
    />
</svg>);

export default Logo;
