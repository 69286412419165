import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Range from '../../Range';
import SingleInputFilter from '../../SingleInputFilter';

import ColorFilter from './ColorFilter';
import ComplicatedFilter from './ComplicatedFilter';
import RadioButtonsFilter from './RadioButtonsFilter';
import CheckboxFilter from './CheckboxFilter';
import {
  AMOUNT_C,
  COLOR_C,
  COMPLICATED_C,
  FLOAT_C,
  INTEGER_C,
  LIST_C,
  PRICE_C,
} from '../../../consts';

class FilterTypeSelector extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    dictionaries: PropTypes.array.isRequired,
    maxPrice: PropTypes.number.isRequired,
    minPrice: PropTypes.number.isRequired,
  };

  render() {
    const {
      item,
      dictionaries,
      maxPrice,
      minPrice,
    } = this.props;
    if (item.name === PRICE_C && maxPrice) {
      return (
        <Range
          max={maxPrice}
          min={minPrice}
          _id={item._id}
        />
      );
    }
    if (item.type === FLOAT_C || item.type === INTEGER_C) {
      return <SingleInputFilter _id={item._id} />;
    }
    if (item.type === LIST_C && dictionaries.length !== 0) {
      if (item.name === COLOR_C) {
        const colorDictionary = dictionaries.find((dictionary) => dictionary.name === COLOR_C);
        if (!colorDictionary) { return null; }
        return <ColorFilter colors={colorDictionary.options} item={item} />;
      }
      const dictionary = dictionaries.find((dict) => dict.name === item.name);
      if (!dictionary) { return null; }
      return <CheckboxFilter options={dictionary.options} item={item} />;
    }

    if (item.type === COMPLICATED_C) {
      if (item.name === AMOUNT_C) {
        return <RadioButtonsFilter item={item} />;
      }
      return <ComplicatedFilter item={item} />;
    }
    return null;
  }
}

export default FilterTypeSelector;
