import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Logo from './components/Logo';

import styles from './css/styles.css';

const Header = ({
  days,
  phone,
}) => (
  <header className={styles.header}>
    <div className={styles.left}>
      <h4 className={styles.text}>
        Мы работаем:
      </h4>

      <h4 className={styles.info}>
        {days && days.map((day, index) => {
          if (!day) { return null; }
          return (
            <div key={index}>
              {day}
            </div>
          );
        })}
      </h4>
    </div>

    <Link to='/' className={styles.logo}>
      <Logo />
    </Link>

    <div className={styles.right}>
      <h3 className={styles.phone}>
        {phone}
      </h3>
    </div>
  </header>
);

Header.propTypes = {
  days: PropTypes.array.isRequired,
  phone: PropTypes.string.isRequired,
};

export default Header;
