exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".wrap__3oyW0 {\n  position: relative;\n  padding: 55px 0 50px 31px;\n  color: #2b2b2b;\n}\n\n.title__3PRBv {\n  font-size: 13px;\n  text-transform: uppercase;\n  margin-bottom: 24px;\n}\n\n.productsList__1DIvm {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  max-width: 360px;\n  -ms-flex-wrap: wrap;\n      flex-wrap: wrap;\n  list-style: none;\n}\n\n.productImg__3ay3I {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 52px;\n  height: 52px;\n  margin-right: 10px;\n  margin-bottom: 5px;\n}\n\n.productImg__3ay3I img {\n  width: 100%;\n  height: 100%;\n}\n\n.separator__Jlm79 {\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: block;\n  height: 1px;\n  width: calc(100% - 10px);\n  background: #adadad;\n}", ""]);

// exports
exports.locals = {
	"wrap": "wrap__3oyW0",
	"title": "title__3PRBv",
	"productsList": "productsList__1DIvm",
	"productImg": "productImg__3ay3I",
	"separator": "separator__Jlm79"
};