import React from 'react';
import PropTypes from 'prop-types';

import styles from '../css/styles.css';

const Contacts = ({
  address,
  days,
  phone,
  phone1,
  phone2,
}) => (
  <div className={styles.wrap}>
    <div className={styles.contacts}>
      <h3 className={styles.title}>
        <img src='/img/logo-white.png' alt='stek' />
      </h3>

      <div className={styles.text}>
        Адрес:
        <span> </span>
        {address}
      </div>

      <div className={styles.text}>
        Время работы:
        <div className={styles.days}>
          {days && days.map((day, index) => {
            if (!day) { return null; }
            return (
              <div key={index}>
                {day}
              </div>
            );
          })}
        </div>
      </div>

      <div className={styles.phone}>{phone}</div>
      <div className={styles.phone}>{phone1}</div>
      <div className={styles.phone}>{phone2}</div>
    </div>

    <div className={styles.pinHolder}>
      <img src='/img/pin.png' alt='pin' width={34} height={46} />
    </div>
  </div>
);

Contacts.propTypes = {
  address: PropTypes.string.isRequired,
  days: PropTypes.array.isRequired,
  phone: PropTypes.string.isRequired,
  phone1: PropTypes.string.isRequired,
  phone2: PropTypes.string.isRequired,
};

export default Contacts;
