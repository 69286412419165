import React from 'react';
import PropTypes from 'prop-types';

import Product from './Product';
import styles from '../css/products.css';

const Products = (
  {
    products,
    match,
    location,
    priceId,
    discountId,
    categoryId,
    unitId,
    categoryHref,
  },
) => {
  if (!products) { return null; }
  return (
    <div className={styles.products}>
      {products.map((product) => (
        <Product
          location={location}
          product={product}
          match={match}
          priceId={priceId}
          key={product._id}
          discountId={discountId}
          categoryId={categoryId}
          unitId={unitId}
          categoryHref={categoryHref}
        />
      ))}
    </div>
  );
};

Products.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  products: PropTypes.array,
  priceId: PropTypes.string.isRequired,
  unitId: PropTypes.string.isRequired,
  discountId: PropTypes.string.isRequired,
  categoryHref: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
};

Products.defaultProps = {
  products: [],
};

export default Products;
