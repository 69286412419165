import { API_URL, GET_OPTIONS } from '../config';

export const getApi = (endpoint, params = {}) => {
  const { url = API_URL, options = GET_OPTIONS } = params;

  return (
    global.fetch(`${url}/${endpoint}`, options)
      .then((resp) => (resp.json()))
  );
};

export const postApi = (endpoint, params = {}) => {
  const { url = API_URL, body = {} } = params;

  return (
    global.fetch(`${url}/${endpoint}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then((resp) => (resp.json()))
  );
};

export default getApi;
