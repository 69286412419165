import React from 'react';
import PropTypes from 'prop-types';

import { deleteFromMultipleFilter, deleteFromSingleFilter } from '../helpers/setAndParseQueryString';
import ActiveFilterItem from './ActiveFilterItem';
import { AMOUNT_C } from '../../../consts';

const ComplicatedActiveFilter = (
  {
    match,
    location,
    history,
    value,
    attribute,
  },
) => {
  if (!attribute.options) { return null; }
  if (attribute.name === AMOUNT_C) {
    const filterOption = attribute.options.find((item) => {
      return item._id === value;
    });

    if (!filterOption) { return null; }
    const label = filterOption.label;

    return (
      <ActiveFilterItem
        match={match}
        location={location}
        history={history}
        label={label}
        value={value}
        _id={attribute._id}
        key={value}
        deleteHandler={attribute.name === AMOUNT_C ? deleteFromSingleFilter : deleteFromMultipleFilter}
      />);
  }

  return value.map((option) => {
    const filterOption = attribute.options.find((item) => {
      return item._id === option;
    });

    if (!filterOption) { return null; }
    const label = filterOption.label;

    return (
      <ActiveFilterItem
        match={match}
        location={location}
        history={history}
        label={label}
        value={option}
        _id={attribute._id}
        key={option}
        deleteHandler={deleteFromMultipleFilter}
      />
    );
  });
};

ComplicatedActiveFilter.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  attribute: PropTypes.object.isRequired,
};

export default ComplicatedActiveFilter;
