import React from 'react';
import PropTypes from 'prop-types';

import Desription from './components/Description';
import Image from '../Image';

import styles from './css/styles.css';

const Card = ({
  img,
  title,
  subtitle,
  text,
}) => (
  <section className={styles.card}>
    <Image className={styles.image} img={img} alt={title} />

    <Desription
      title={title}
      subtitle={subtitle}
      text={text}
    />
  </section>
);

Card.propTypes = {
  img: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};


export default Card;
