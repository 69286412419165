exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.article__1JKC4 {\n    position: absolute;\n    top: calc(50% - 28px);\n    right: 0;\n    left: 0;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    height: 284px;\n    -webkit-transform: translateY(-50%);\n            transform: translateY(-50%);\n    background-color: rgba(0, 0, 0, .3);\n}\n\n.textBox__3sTal {\n    max-width: 440px;\n    margin: 0 0 0 380px;\n    overflow: hidden;\n\n    -webkit-animation: fadeIn__o9px_ 2s ease;\n\n            animation: fadeIn__o9px_ 2s ease;\n}\n\n.title__2kFCz {\n    max-width: 280px;\n    color: #ffffff;\n    font-size: 24px;\n    font-weight: 700;\n    line-height: 30px;\n    text-transform: uppercase;\n    letter-spacing: 0.27px;\n}\n\n.title__2kFCz:after {\n    content: '';\n    display: block;\n    width: 50px;\n    height: 2px;\n    margin: 22px 0;\n    background-color: #fefefe;\n    opacity: 0.5;\n    -webkit-transform: translateX(-100%);\n            transform: translateX(-100%);\n    -webkit-animation: moveIn__1idhO 0.5s ease 1s forwards;\n            animation: moveIn__1idhO 0.5s ease 1s forwards;\n}\n\n.text__3a2c4 {\n    color: #fdfdfd;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n    letter-spacing: 0.36px;\n}\n\n@-webkit-keyframes fadeIn__o9px_ {\n    from { opacity: 0; }\n    to { opacity:  1; }\n}\n\n@keyframes fadeIn__o9px_ {\n    from { opacity: 0; }\n    to { opacity:  1; }\n}\n\n@-webkit-keyframes moveIn__1idhO {\n    to { -webkit-transform: translateX(0); transform: translateX(0); }\n}\n\n@keyframes moveIn__1idhO {\n    to { -webkit-transform: translateX(0); transform: translateX(0); }\n}", ""]);

// exports
exports.locals = {
	"article": "article__1JKC4",
	"textBox": "textBox__3sTal",
	"fadeIn": "fadeIn__o9px_",
	"title": "title__2kFCz",
	"moveIn": "moveIn__1idhO",
	"text": "text__3a2c4"
};