import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import whyDidYouUpdate from 'why-did-you-update';
import { Router } from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';
import App from './App';
import { configureStore } from './store';

const history = createBrowserHistory();
//
// if (process.env.NODE_ENV !== 'production') {
//   whyDidYouUpdate(React);
// }

const store = configureStore({}, history);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);
