import React from 'react';
import PropTypes from 'prop-types';

import Paragraph from './Paragraph';

import styles from '../css/styles.css';

const Header = ({ title, subtitle }) => (
  <header className={styles.header}>
    <h3
      className={styles.title}
    >
      {title}
    </h3>

    <Paragraph text={subtitle} />
  </header>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Header;
