import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';
import styles from '../css/sortPanelLinks.css';

const SortLink = (
  {
    match,
    location,
    link,
    className,
    toggle,
  },
) => {
  const parsedSearch = queryString.parse(location.search);

  const isActive = !parsedSearch[link.field]
    ? link.default
    : parsedSearch[link.field] === link.type;

  if (toggle && isActive) {
    delete parsedSearch[link.field];
  } else {
    parsedSearch[link.field] = link.type;
  }

  const to = {
    pathname: match.url,
    search: queryString.stringify(parsedSearch),
  };

  return (!link.type
    ? <span className={cx(styles.link, styles.slash)}>{link.name(isActive)}</span>
    : (
      <NavLink
        exact
        className={cx(styles.link, className)}
        activeClassName={cx({ [styles.activeLink]: isActive })}
        to={to}
      >
        {link.name(isActive)}
      </NavLink>)
  );
};

SortLink.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  link: PropTypes.object.isRequired,
  className: PropTypes.string,
  toggle: PropTypes.bool,
};

SortLink.defaultProps = {
  className: '',
  toggle: false,
};

export default SortLink;
