exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".range__1SnWG {\n  position: relative;\n}\n\n.values__2LIoE {\n  margin: 0 0 12px 0;\n}\n\n.input__2iOk0 {\n  width: 64px;\n  padding: 0 8px;\n}\n\n.label__DGqOo {\n  display: inline-block;\n  margin: 0 0 0 8px;\n  color: #3d3d3d;\n  font-size: 13px;\n  font-weight: 600;\n  text-transform: uppercase;\n  letter-spacing: 0.39px;\n}\n\n.submit__I5kMU {\n  position: absolute;\n  top: 0;\n  right: -6px;\n  width: 32px;\n  height: 32px;\n  border: 1px solid black;\n  cursor: pointer;\n}\n\n.submit__I5kMU:after {\n  content: '';\n  display: block;\n  position: absolute;\n  top: 9px;\n  left: 7px;\n  width: 16px;\n  height: 13px;\n  background: transparent;\n  background: url('/img/checkmark.svg') center no-repeat;\n}", ""]);

// exports
exports.locals = {
	"range": "range__1SnWG",
	"values": "values__2LIoE",
	"input": "input__2iOk0",
	"label": "label__DGqOo",
	"submit": "submit__I5kMU"
};