import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import styles from './css/styles.css';

const Navigation = ({ categories }) => {
  if (!categories || categories.length === 0) { return null; }
  return (
    <ul className={styles.nav}>
      {categories.map((item) => {
        const href = item.active ? `/category/${item.href}` : '/dummy';
        return (
          <li className={styles.navItem} key={item._id}>
            <NavLink to={href} className={styles.navLink}>{item.label}</NavLink>
          </li>
        );
      })}
    </ul>
  );
};

Navigation.propTypes = {
  categories: PropTypes.array,
};

Navigation.defaultProps = {
  categories: [],
};

export default Navigation;
