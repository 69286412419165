import React from 'react';

import styles from '../css/styles.css';

const Logo = () => (
  <div className={styles.logo}>
    <img src='/img/logo-white.png' alt='logo' />
  </div>
);

export default Logo;
