import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FilterContent from './FilterContent';

import styles from '../css/styles.css';
import { DISCOUNT_C } from '../../../consts';

class FilterItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    activeItem: PropTypes.number.isRequired,
    maxPrice: PropTypes.number.isRequired,
    minPrice: PropTypes.number.isRequired,
    dictionaries: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
  };

  onClick = () => {
    const { onClick, index, activeItem } = this.props;
    if (activeItem === index) {
      onClick(null);
    } else {
      onClick(index);
    }
  };

  render() {
    const {
      item,
      activeItem,
      index,
      dictionaries,
      maxPrice,
      minPrice,
    } = this.props;
    const isActive = activeItem === index;
    const { label, name, type } = item;

    if (name === DISCOUNT_C) {
      return null;
    }

    return (
      <li className={styles.filterItem}>
        <h3 // eslint-disable-line
          className={styles.itemTitle}
          onClick={this.onClick}
        >
          {label}
        </h3>

        {
          isActive
          && (
            <FilterContent
              name={name}
              type={type}
              maxPrice={maxPrice}
              minPrice={minPrice}
              item={item}
              dictionaries={dictionaries}
            />
          )
        }
      </li>
    );
  }
}

export default FilterItem;
