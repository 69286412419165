import React from 'react';
import PropTypes from 'prop-types';
import styles from '../css/sortPanelLinks.css';

const GridIconTile = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="21"
    viewBox="0 0 20 21"
    className={styles.icon}
  >
    <defs>
      <path
        className={styles.path}
        id="5jtua"
        d="M1229.9 567.23c-.7 0-1.28-.6-1.28-1.34v-3c0-.73.57-1.33 1.28-1.33h2.84c.7 0 1.27.6 1.27 1.34v2.99a1.3 1.3 0 0 1-1.27 1.34zm6.8 0c-.7 0-1.27-.6-1.27-1.34v-3c0-.73.57-1.33 1.28-1.33h2.84c.7 0 1.27.6 1.27 1.34v2.99a1.3 1.3 0 0 1-1.27 1.34zm6.82 0c-.7 0-1.28-.6-1.28-1.34v-3c0-.73.57-1.33 1.28-1.33h2.84c.7 0 1.27.6 1.27 1.34v2.99a1.3 1.3 0 0 1-1.27 1.34zm-13.62 7.14c-.7 0-1.28-.6-1.28-1.34v-3c0-.73.57-1.33 1.28-1.33h2.84c.7 0 1.27.6 1.27 1.34v2.99a1.3 1.3 0 0 1-1.27 1.34zm6.8 0c-.7 0-1.27-.6-1.27-1.34v-3c0-.73.57-1.33 1.28-1.33h2.84c.7 0 1.27.6 1.27 1.34v2.99a1.3 1.3 0 0 1-1.27 1.34zm6.82 0c-.7 0-1.28-.6-1.28-1.34v-3c0-.73.57-1.33 1.28-1.33h2.84c.7 0 1.27.6 1.27 1.34v2.99a1.3 1.3 0 0 1-1.27 1.34zm-13.62 7.14c-.7 0-1.28-.6-1.28-1.34v-3c0-.73.57-1.33 1.28-1.33h2.84c.7 0 1.27.6 1.27 1.34v3a1.3 1.3 0 0 1-1.27 1.33zm6.8 0c-.7 0-1.27-.6-1.27-1.34v-3c0-.73.57-1.33 1.28-1.33h2.84c.7 0 1.27.6 1.27 1.34v3a1.3 1.3 0 0 1-1.27 1.33zm6.82 0c-.7 0-1.28-.6-1.28-1.34v-3c0-.73.57-1.33 1.28-1.33h2.84c.7 0 1.27.6 1.27 1.34v3a1.3 1.3 0 0 1-1.27 1.33z"
      />
    </defs>
    <g>
      <g transform="translate(-1228 -561)">
        <use
          fill={isActive ? '#2d2d2d' : '#cfcfcf'}
          xlinkHref="#5jtua"
          className={styles.gridIcon}
        />
      </g>
    </g>
  </svg>
);

GridIconTile.propTypes = {
  isActive: PropTypes.bool,
};

GridIconTile.defaultProps = {
  isActive: false,
};

export default GridIconTile;
