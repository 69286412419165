import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import cx from 'classnames';
import find from 'lodash/find';
import { Link } from 'react-router-dom';

import styles from '../css/product.css';
import Image from '../../../components/Image/component';
import ProductPrice from './ProductPrice';
import { addProductToCompare as addProductToCompareAction } from '../../Compare/actions';
import { COMPARE } from '../../../consts';
import { getComparedProductsByCategoryId } from '../../Compare/selectors';

const Product = (
  {
    product,
    match,
    location,
    priceId,
    discountId,
    unitId,
    categoryHref,
    categoryId,
    addProductToCompare,
    comparedProducts,
  },
) => {
  if (!product) { return null; }
  const attributes = product.attributes;
  const image = find(product.images, (item) => item.position === 0) || product.images[0] || { src: '/img/cover.png' };
  const name = product.shortName || product.name;
  const parsedSearch = queryString.parse(location.search);
  const price = attributes ? (attributes[priceId] && Math.ceil(attributes[priceId], 0)) : null;
  const discount = attributes ? (attributes[discountId] && Math.ceil(attributes[discountId], 0)) : null;
  const unitValue = attributes ? attributes[unitId] : null;
  const isSwatch = !parsedSearch.view || parsedSearch.view === 'swatch';

  const productLink = `/product/${product.seoURL || product._id}`;
  const categoryPath = match.params.href;
  const to = { pathname: productLink, state: { categoryPath, categoryHref, categoryId } };

  const disableCompare = comparedProducts.length >= 5 || comparedProducts.includes(product._id);

  return (
    <div className={cx(styles.productWrap, { [styles.gridWrap]: !isSwatch })}>
      <div className={cx(styles.imageWrap, { [styles.gridImageWrap]: !isSwatch })}>
        <Image img={image} classNameImage={styles.innerImage} className={cx(styles.image, { [styles.gridImage]: !isSwatch })} />
        <div className={styles.buttonsWrap}>
          <Link to={to} className={styles.navLink}>
            <button type='button' className={styles.button}>описание</button>
          </Link>
          <button
            type='button'
            className={cx(styles.button, { [styles.disabled]: disableCompare })}
            onClick={() => addProductToCompare(product._id, categoryId)}
          >
            {COMPARE}
          </button>
        </div>
      </div>
      <Link to={to} className={styles.navLink}>
        <div className={cx({ [styles.gridWrapContent]: !isSwatch })}>
          <div className={styles.name}>
            {name}
          </div>
          <ProductPrice price={price} unit={unitValue} discount={discount} />
        </div>
      </Link>
    </div>
  );
};

Product.propTypes = {
  location: PropTypes.object.isRequired,
  comparedProducts: PropTypes.array,
  match: PropTypes.object.isRequired,
  product: PropTypes.object,
  priceId: PropTypes.string.isRequired,
  unitId: PropTypes.string.isRequired,
  discountId: PropTypes.string.isRequired,
  categoryHref: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  addProductToCompare: PropTypes.func.isRequired,
};

Product.defaultProps = {
  product: [],
  comparedProducts: [],
};

const mapStateToProps = (state, { categoryId }) => ({
  comparedProducts: getComparedProductsByCategoryId(categoryId),
});

export default connect(mapStateToProps, {
  addProductToCompare: addProductToCompareAction,
})(Product);
