import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Checkbox from '../../Checkbox';
import { parseQueryString, setMultipleFilter } from '../helpers/setAndParseQueryString';

const onChange = (
  {
    match,
    location,
    history,
    optionId,
    optionsId,
  },
) => () => {
  setMultipleFilter(
    {
      location,
      match,
      history,
      value: optionId,
      _id: optionsId,
    },
  );
};

const CheckboxFilter = (
  {
    options,
    item,
    location,
    history,
    match,
  },
) => {
  if (!options || !options.length || !item) { return null; }
  const parsedSearch = parseQueryString({ string: location.search });

  return options.map((option) => {
    const checked = (parsedSearch
      && parsedSearch.filters
      && parsedSearch.filters[item._id]
      && Array.isArray(parsedSearch.filters[item._id])
      && parsedSearch.filters[item._id].includes(option._id)) || false;

    return (
      <Checkbox
        optionsId={item._id}
        id={option._id}
        checked={checked}
        label={option.label}
        onChange={onChange({
          match,
          location,
          history,
          optionId: option._id,
          optionsId: item._id,
        })}
        value={option.value}
        key={option._id}
      />
    );
  });
};

CheckboxFilter.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(CheckboxFilter);
