exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".input__2UhB8 {\n  max-width: 104px;\n  height: 32px;\n  padding: 0 12px;\n  background-color: #ffffff;\n  border-radius: 2px;\n  border: 1px solid #dddddd;\n  color: #3d3d3d;\n  font-size: 13px;\n  font-weight: 700;\n  text-transform: uppercase;\n  letter-spacing: 0.39px;\n  outline: 0;\n}", ""]);

// exports
exports.locals = {
	"input": "input__2UhB8"
};