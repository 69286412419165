import React from 'react';
import PropTypes from 'prop-types';

import { deleteFromMultipleFilter } from '../helpers/setAndParseQueryString';
import ActiveFilterItem from './ActiveFilterItem';

const MultipleActiveFilter = (
  {
    match,
    location,
    history,
    value,
    dictionaries,
    attributes,
    attrId,
  },
) => {
  const attribute = attributes.find((attr) => attr._id === attrId);

  if (!attribute) { return null; }
  const dictionary = dictionaries.find((dict) => dict.name === attribute.name);

  if (!dictionary || !dictionary.options) { return null; }

  return value.map((item) => {
    const option = dictionary.options.find((opt) => opt._id === item);

    if (!option) { return null; }
    return (
      <ActiveFilterItem
        key={item}
        match={match}
        location={location}
        history={history}
        label={option.label}
        value={item}
        _id={attrId}
        deleteHandler={deleteFromMultipleFilter}
      />
    );
  });
};

MultipleActiveFilter.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dictionaries: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
  attrId: PropTypes.string.isRequired,
};

export default MultipleActiveFilter;
