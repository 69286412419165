import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { Link } from 'react-router-dom';

import styles from '../css/otherProductColors.css';
import {
  OTHER_COLORS,
  IMAGES,
} from '../../../consts';
import Image from '../../../components/Image';

const OtherProductColors = ({ products, categoryId, categoryHref }) => {
  if (!products || isEmpty(products)) return null;

  return (
    <section className={styles.wrap}>
      <h2 className={styles.title}>
        {OTHER_COLORS}
      </h2>

      <ul className={styles.productsList}>
        {map(products, (product, index) => {
          const images = get(product, IMAGES, []);
          const image = find(images, (item) => item.position === 0) || images[0];
          const _id = get(product, '_id', '');
          const seoURL = get(product, 'seoURL', null);
          const productLink = `/product/${seoURL || _id}`;

          if (!image) return null;

          return (
            <Link
              to={{ pathname: productLink, state: { categoryId, categoryHref } }}
              className={styles.productImg}
              key={index}
            >
              <Image img={image} />
            </Link>
          );
        })}
      </ul>

      <div className={styles.separator} />
    </section>
  );
};

OtherProductColors.propTypes = {
  products: PropTypes.array.isRequired,
  categoryId: PropTypes.string.isRequired,
  categoryHref: PropTypes.string.isRequired,
};

export default OtherProductColors;
