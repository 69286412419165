import React from 'react';
import PropTypes from 'prop-types';

import styles from '../css/activeFilters.css';
import {
  parseQueryString, deleteFromSingleFilter,
} from '../helpers/setAndParseQueryString';
import ActiveFilterItem from './ActiveFilterItem';
import MultipleActiveFilter from './MultipleActiveFilter';
import { COMPLICATED_C, DISCOUNT_C } from '../../../consts';
import ComplicatedActiveFilter from './ComplicatedActiveFilter';

const ActiveFilters = (
  {
    match,
    location,
    history,
    maxPrice,
    dictionaries,
    attributes,
  },
) => {
  const parsedSearch = parseQueryString({ string: location.search });
  const filters = parsedSearch.filters;

  if (!filters) { return null; }

  const keys = Object.keys(filters);

  if (keys.length === 0) { return null; }

  return (
    <div className={styles.wrap}>
      {keys.map((key) => {
        const value = filters[key];
        const attribute = attributes.find((item) => item._id === key);
        const attributeType = attribute && attribute.type;

        if (!attribute || (attribute && attribute.name === DISCOUNT_C)) return null;

        if (attributeType === COMPLICATED_C) {
          return (
            <ComplicatedActiveFilter
              attribute={attribute}
              location={location}
              match={match}
              history={history}
              value={value}
              key={key}
            />
          );
        }

        if (Array.isArray(value)) {
          return (
            <MultipleActiveFilter
              attributes={attributes}
              location={location}
              match={match}
              dictionaries={dictionaries}
              history={history}
              value={value}
              key={key}
              attrId={key}
            />
          );
        }

        if (typeof value === 'object') {
          if (!value.min && !value.max) { return null; }
          const min = value.min || 0;
          const max = value.max || maxPrice;
          const completedValue = `${min} - ${max} грн/м<sup>2</sup>`;
          return (
            <ActiveFilterItem
              match={match}
              location={location}
              history={history}
              value={completedValue}
              label={completedValue}
              _id={key}
              key={key}
              deleteHandler={deleteFromSingleFilter}
            />
          );
        }

        return (
          <ActiveFilterItem
            match={match}
            location={location}
            history={history}
            label={value}
            value={value}
            _id={key}
            key={key}
            deleteHandler={deleteFromSingleFilter}
          />
        );
      })}
    </div>
  );
};

ActiveFilters.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  maxPrice: PropTypes.number.isRequired,
  dictionaries: PropTypes.array.isRequired,
  attributes: PropTypes.array.isRequired,
};

export default ActiveFilters;
