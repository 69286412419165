exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".sortPanel__1ZmzU {\n    height: 170px;\n    width: 100%;\n    padding: 24px 24px 15px 24px;\n    position: -webkit-sticky;\n    position: relative;\n    z-index: 20;\n    background-color: white;\n    border-bottom: 1px solid #adadad;\n}\n.title__2gEcN {\n    color: #2b2b2b;\n    font-family: \"Open Sans\";\n    font-size: 24px;\n    line-height: 52.8px;\n    font-weight: 700;\n    text-transform: uppercase;\n    letter-spacing: 0.21px;\n}\n.counter__eAagt {\n    color: #343434;\n    font-family: \"Open Sans\";\n    font-weight: 300;\n    font-size: 12px;\n    line-height: 19.23px;\n    letter-spacing: 0.31px;\n}", ""]);

// exports
exports.locals = {
	"sortPanel": "sortPanel__1ZmzU",
	"title": "title__2gEcN",
	"counter": "counter__eAagt"
};