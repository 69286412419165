import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './css/styles.css';

const Image = ({
  className,
  img,
  width,
  height,
  style,
  alt,
  classNameImage,
}) => (
  <div className={cx(styles.media, className)}>
    <span className={styles.holder}>
      <img
        className={cx(styles.image, classNameImage)}
        src={img.src}
        width={width}
        height={height}
        style={style}
        alt={alt}
      />
    </span>

    <div className={styles.mask} />
  </div>
);

Image.propTypes = {
  className: PropTypes.string,
  classNameImage: PropTypes.string,
  img: PropTypes.object,
  style: PropTypes.object,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  alt: PropTypes.string,
};

Image.defaultProps = {
  className: null,
  classNameImage: null,
  width: null,
  height: null,
  img: {},
  style: {},
  alt: 'Err!',
};

export default Image;
