import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { postApi } from '../../../utils/getApi';
import { productPageConfig } from '../Product/config';
import CompareAttribute from './components/CompareAttribute';

import {
  BACK_TO_CATALOGUE,
  CATEGORY_HREF,
  LABEL,
  NAME,
  PRODUCTS_COMPARE,
} from '../../consts';
import Image from '../../components/Image';
import styles from './css/styles.css';

export class Compare extends Component {
  static propTypes = {
    // location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    removeProductFromCompare: PropTypes.func.isRequired,
    comparedProducts: PropTypes.array,
  };

  state = {
    products: [],
    category: {},
  };

  static defaultProps = {
    comparedProducts: [],
  };

  returnToCatalog = () => {
    const { history } = this.props;
    const { category } = this.state;
    const categoryHref = `/category/${category.href}`;
    const search = (history.location.state && history.location.state.search) || '';
    history.push({ pathname: categoryHref, search });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params._id !== this.props.match.params._id || nextProps.comparedProducts !== this.props.comparedProducts) {
      this.getProductsData(nextProps);
    }
  }

  componentDidMount() {
    this.getProductsData(this.props);
  }

  removeProduct = (productId) => (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const { removeProductFromCompare } = this.props;
    const { category } = this.state;
    removeProductFromCompare(productId, category._id);
  };

  getProductsData = ({ comparedProducts, match }) => {
    postApi(`compare/${match.params._id}`, { body: { products: comparedProducts } })
      .then((result) => {
        if (!result) return;

        this.setState({
          products: result.products,
          category: result.category,
        });
      })
      .catch((error) => console.log('Error on category page fetch', error)); // eslint-disable-line
  };

  render() {
    const { products, category } = this.state;
    const { comparedProducts, history } = this.props;

    if (!products || !category || products.length < 2) { return null; }
    const disableDelete = products.length <= 2;
    const categoryId = category._id;

    // TODO check all categoryHref links and try to resolve link hell
    // const categoryHref = category.href;
    const categoryHref = get(history.location.state, CATEGORY_HREF, '');
    const order = productPageConfig[categoryId] && productPageConfig[categoryId].compare;

    return (
      <main className={styles.contentWrap}>
        <h1 className={styles.title}>{PRODUCTS_COMPARE}</h1>
        <div
          className={styles.returnBtn}
          onClick={this.returnToCatalog}
        >
          <div className={styles.btn} />
          <p>
            {BACK_TO_CATALOGUE}
          </p>
        </div>
        <table className={styles.wrap}>
          <thead>
            <th className={styles.th}>
              {category.label}
              :
            </th>
            {comparedProducts.map((productId) => {
              const product = products.find((prod) => prod && prod._id === productId);

              if (!product) { return null; }

              const attributes = product.attributes;
              const discount = attributes && attributes.discount;
              const price = attributes && attributes.price;
              const unit = attributes && attributes.unit && attributes.unit.value;
              const currentPrice = (discount && discount.value && discount.value.toFixed(2)) || (price && price.value.toFixed(2));
              const seoURL = get(product, 'seoURL', null);
              const productLink = `/product/${seoURL || product._id}`;
              const path = { pathname: productLink, state: { categoryId, categoryHref } };
              return (
                <th className={styles.td}>
                  <Link to={path} className={styles.link}>
                    <button
                      type='button'
                      className={cx(styles.delete, { [styles.disable]: disableDelete })}
                      onClick={this.removeProduct(product._id)}
                    />
                    <div className={styles.imageWrap}>
                      <Image className={styles.image} img={product.images[0]} />
                    </div>
                    <div className={styles.name}>
                      {product.shortName}
                    </div>
                    <div className={styles.price}>
                      {currentPrice}
                      <span className={styles.unit}>{` грн/${unit}`}</span>
                    </div>
                  </Link>
                </th>
              );
            })}
          </thead>
          <tbody>
            {order && order.map((item) => {
              const itemName = get(item, NAME, null);
              const categoryAttribute = category.attributeIds.find((attribute) => {
                return attribute.name.toLowerCase() === itemName;
              });

              const label = get(item, LABEL, categoryAttribute && categoryAttribute.label);

              return (
                <tr>
                  <td className={styles.th}>
                    {label}
                  </td>
                  {comparedProducts.map((productId) => {
                    const product = products.find((prod) => prod && prod._id === productId);
                    if (!product) { return null; }
                    return (
                      <td className={styles.td}>
                        <CompareAttribute item={item} itemName={itemName} product={product} />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </main>
    );
  }
}

export default Compare;
