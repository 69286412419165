import get from 'lodash/get';
import { COMPARED_PRODUCTS } from '../../consts';

export const getComparedProducts = () => JSON.parse(localStorage.getItem(COMPARED_PRODUCTS)) || {};

export const getComparedProductsByCategoryId = (categoryId) => {
  const comparedProducts = getComparedProducts();

  return get(comparedProducts, categoryId);
};
