import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from '../css/product.css';

const ProductPrice = (
  {
    discount,
    classNamePrice,
    classNameUnit,
    unit,
    price,
  },
) => {
  const unitValue = unit ? `грн/${unit}` : 'грн';

  return (
    <div className={styles.priceWrap}>
      {!!discount
      && (
        <div className={cx(styles.price, classNamePrice)}>
          {discount}
          <span className={cx(styles.unit, classNameUnit)}>{unitValue}</span>
        </div>
      )}
      <div className={cx(styles.price, classNamePrice, { [styles.cross]: !!discount })}>
        {price}
        <span className={cx(styles.unit, classNameUnit)}>{unitValue}</span>
      </div>
    </div>
  );
};

ProductPrice.propTypes = {
  price: PropTypes.number.isRequired,
  unit: PropTypes.string,
  classNamePrice: PropTypes.string,
  classNameUnit: PropTypes.string,
  discount: PropTypes.number,
};

ProductPrice.defaultProps = {
  discount: null,
  classNamePrice: '',
  unit: '',
  classNameUnit: '',
};

export default ProductPrice;
