import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import styles from '../css/styles.css';
import {
  LABEL,
  VALUE,
} from '../../../consts';
import Image from '../../../components/Image';

export class CompareAttribute extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    itemName: PropTypes.object.isRequired,
  };

  render() {
    const { item, product, itemName } = this.props;
    const attributes = product.attributes;
    const attribute = get(attributes, itemName, null);
    let value = get(attribute, VALUE, null);

    if (item.values && !item.images) {
      value = item.values
        .map((element) => {
          return get(attributes[element.name], LABEL, null);
        })
        .filter((element) => element !== null)
        .join(', ');
    }

    if (item.values && item.images) {
      value = item.values
        .map((element) => {
          const attr = get(attributes, element.name);
          const image = get(attr, 'image', null);
          const attrLabel = get(attr, LABEL, null);
          if (!image) {
            return null;
          }
          return (
            <div className={styles.iconWrap}>
              <div className={styles.imageWrap}>
                <Image className={styles.icon} img={image} />
              </div>
              <div className={styles.label}>
                {attrLabel}
              </div>
            </div>
          );
        });
    }
    return (
      <div className={styles.tdWrap}>
        {value}
      </div>
    );
  }
}

export default CompareAttribute;
