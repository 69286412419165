import { connect } from 'react-redux';
import Component from './component';
import { getComparedProductsByCategoryId } from './selectors';
import { removeProductFromCompare as removeProductFromCompareAction } from './actions';

const mapStateToProps = (state, props) => {
  const products = getComparedProductsByCategoryId(props.match.params._id);
  return {
    comparedProducts: products,
  };
};

export default connect(mapStateToProps, { removeProductFromCompare: removeProductFromCompareAction })(Component);
