exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".header__1wXFn {\n  position: relative;\n  width: 1200px;\n  height: 105px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  margin: 0 auto;\n  padding: 8px 0 0 0;\n  border-bottom: 1px solid #adadad;\n  color: #343434;\n  letter-spacing: 0.45px;\n}\n\n.logo__2Vy7w {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n  height: 54px;\n}\n\n.logo__2Vy7w svg {\n  position: relative;\n  height: 100%;\n  top: 6px;\n}\n\n.st0__6fi4M{\n  fill:#66788F;\n}\n\n.st1__2KrJc{\n  fill:#73839B;\n}\n\n.st2__1osfS{\n  fill:none;\n  fill-rule:evenodd;\n  clip-rule:evenodd;\n}\n\n.info__3ASZz {\n  font-weight: 700;\n  font-size: 14px;\n  letter-spacing: 1px;\n}\n\n.text__1nKw8 {\n  font-weight: 400;\n  font-size: 14px;\n  letter-spacing: 0.25px;\n}\n\n.phone__TD0SV {\n  font-weight: 700;\n  font-size: 22px;\n  letter-spacing: 0.35px;\n}", ""]);

// exports
exports.locals = {
	"header": "header__1wXFn",
	"logo": "logo__2Vy7w",
	"st0": "st0__6fi4M",
	"st1": "st1__2KrJc",
	"st2": "st2__1osfS",
	"info": "info__3ASZz",
	"text": "text__1nKw8",
	"phone": "phone__TD0SV"
};