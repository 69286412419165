import React from 'react';
import PropTypes from 'prop-types';

import SortPanel from './SortPanel';
import Products from './Products';
import ProductsLinks from './ProductsLinks';
import Filter from '../../../components/Filter';

import ActiveFilters from '../../../components/Filter/components/ActiveFilters';
import { clearFilters, parseQueryString } from '../../../components/Filter/helpers/setAndParseQueryString';
import CompareLine from '../../Compare/components/CompareLine';

import styles from '../css/categoryContent.css';

const clearFiltersHandler = ({ location, match, history }) => () => {
  clearFilters({ location, match, history });
};

const CategoryContent = (
  {
    category,
    products,
    match,
    location,
    priceId,
    discountId,
    unitId,
    productsLength,
    dictionaries,
    filtersAttributes,
    maxPrice,
    minPrice,
    history,
  },
) => {
  if (!category || !products) { return null; }

  const parsedSearch = parseQueryString({ string: location.search });
  const filters = Object.keys(parsedSearch.filters || {});
  const filtersWithoutStock = filters.filter((item) => item !== discountId);
  const showDeleteAllButton = parsedSearch.filters && filtersWithoutStock.length > 0;

  const categoryHref = history.createHref(location);
  // const categoryPath = category && category.href;
  const categoryId = category && category._id;

  return (
    <div className={styles.contentWrap}>
      <CompareLine
        style={styles.compareLine}
        location={location}
        categoryHref={categoryHref}
        categoryId={categoryId}
      />

      <div className={styles.filterPanel}>
        {showDeleteAllButton
        && (
          <button
            type='button'
            className={styles.deleteAll}
            onClick={clearFiltersHandler({ location, match, history })}
          />)
        }
        <div className={styles.scrollWrap}>
          <ActiveFilters
            maxPrice={maxPrice}
            match={match}
            dictionaries={dictionaries}
            attributes={filtersAttributes}
            location={location}
            history={history}
          />
          <Filter
            filtersAttributes={filtersAttributes}
            maxPrice={maxPrice}
            minPrice={minPrice}
            dictionaries={dictionaries}
          />
        </div>
      </div>
      <div className={styles.wrap}>
        <SortPanel
          history={history}
          match={match}
          productsLength={productsLength}
          location={location}
          category={category}
          discountId={discountId}
        />
        <Products
          location={location}
          match={match}
          priceId={priceId}
          productsLength={productsLength}
          products={products}
          discountId={discountId}
          categoryId={category._id}
          unitId={unitId}
          categoryHref={categoryHref}
        />
        <ProductsLinks
          location={location}
          match={match}
          productsLength={productsLength}
          uploadedProductsLength={products && products.length}
        />
        <a href='#sortpanel' className={styles.anchor}>
          <img src='/img/arrow.png' alt='^' />
        </a>
      </div>
    </div>
  );
};

CategoryContent.propTypes = {
  category: PropTypes.object,
  priceId: PropTypes.string,
  unitId: PropTypes.string,
  discountId: PropTypes.string,
  productsLength: PropTypes.number,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  products: PropTypes.array,
  dictionaries: PropTypes.array.isRequired,
  filtersAttributes: PropTypes.array.isRequired,
  maxPrice: PropTypes.number.isRequired,
  minPrice: PropTypes.number.isRequired,
};

CategoryContent.defaultProps = {
  category: {},
  products: [],
  priceId: '',
  unitId: '',
  discountId: '',
  productsLength: 0,
};

export default CategoryContent;
