import React, { Component } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import { connect } from 'react-redux';
import cx from 'classnames';

import { getApi } from '../../../utils/getApi';
import ProductSlider from './components/ProductSlider';
import SimilarProducts from './components/SimilarProducts';
import ViewedProducts from './components/ViewedProducts';
import Image from '../../components/Image/component';
import Description from './components/Description';
import OtherAttributes from './components/OtherAttributes';
import OtherProductColors from './components/OtherProductColors';
import CompareLine from '../Compare/components/CompareLine';
import { addProductToCompare as addProductToCompareAction } from '../Compare/actions';
import { productPageConfig } from './config';
import styles from './css/styles.css';

import {
  UNIT,
  PRICE,
  AMOUNT,
  DISCOUNT,
  BACK_TO_CATALOGUE,
  CATEGORY_HREF,
  SIMILAR_PRODUCTS_VAR,
  OTHER_COLORS_VAR,
  AVAILABLE,
  NOT_AVAILABLE,
  VIEWED_PRODUCTS,
  COMPARE,
  ID,
  CATEGORY_ID,
  // CATEGORY_PATH,
} from '../../consts';
import { getComparedProductsByCategoryId } from '../Compare/selectors';

export class ProductPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    comparedProducts: PropTypes.array,
    addProductToCompare: PropTypes.func.isRequired,
  };

  static defaultProps = {
    comparedProducts: [],
  };

  state = {
    data: {},
  };

  constructor(props) {
    super(props);
    const { match } = props;


    this.getProductData(props);

    const viewedProducts = JSON.parse(localStorage.getItem(VIEWED_PRODUCTS));

    if (viewedProducts) {
      const updatedViewProducts = this.getUpdatedViewProducts(match.params.href, viewedProducts);

      localStorage.setItem(VIEWED_PRODUCTS, JSON.stringify(updatedViewProducts));
    } else {
      localStorage.setItem(VIEWED_PRODUCTS, JSON.stringify([match.params.href]));
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      location,
      match,
    } = this.props;

    const viewedProducts = JSON.parse(localStorage.getItem(VIEWED_PRODUCTS));

    if (nextProps.location.search !== location.search || nextProps.match.params.href !== match.params.href) {
      window.scrollTo(0, 0);

      this.getProductData(nextProps);

      const updatedViewProducts = this.getUpdatedViewProducts(nextProps.match.params.href, viewedProducts);

      if (updatedViewProducts !== viewedProducts) {
        localStorage.setItem(VIEWED_PRODUCTS, JSON.stringify(updatedViewProducts));
      }
    }
  }

  getUpdatedViewProducts = (product, viewedProducts) => {
    const updatedProducts = [...viewedProducts];

    if (!includes(updatedProducts, product)) {
      if (updatedProducts.length > 6) {
        updatedProducts.shift();
        updatedProducts.push(product);
      } else {
        updatedProducts.push(product);
      }
    }

    return updatedProducts;
  };

  getProductData = (nextProps) => {
    const { match, location } = nextProps;
    const href = match.params.href;

    getApi(`product/${href}${location.search}`)
      .then((data) => {
        if (!data) return;

        this.setState({
          data,
        });
      })
      .catch((error) => console.log('Error on category page fetch', error)); // eslint-disable-line
  };

  render() {
    const { data } = this.state;
    const {
      history,
      location,
      match,
      addProductToCompare,
      comparedProducts,
    } = this.props;

    if (!data || isEmpty(data) || !data.attributes) return null;

    // const categoryId = location.state.categoryId;
    const id = get(data, ID);
    const categoryId = get(data, CATEGORY_ID);
    const productConfig = productPageConfig[categoryId];

    const ranges = pick(data.attributes, productConfig.leftBlock.rangeAttrs);
    const attributesWithPics = pick(data.attributes, productConfig.leftBlock.picsAttrs);
    const size = get(data.attributes, productConfig.rightBlock.name, { value: 0, label: '' });
    const unit = get(data.attributes, UNIT, { value: '' });
    const price = get(data.attributes, PRICE, { value: 0 });
    const discount = get(data.attributes, DISCOUNT, { value: 0 });
    const amount = get(data.attributes, AMOUNT, { value: 0 });
    const similarProducts = get(data, SIMILAR_PRODUCTS_VAR, []);
    const otherProductColors = get(data, OTHER_COLORS_VAR, []);

    const currentPrice = (discount.value && discount.value.toFixed(2)) || price.value.toFixed(2);

    const categoryHref = get(history.location.state, CATEGORY_HREF, '');
    // const categoryPath = get(history.location.state, CATEGORY_PATH, '');

    const disableCompare = comparedProducts.includes(id) || comparedProducts.length >= 5;

    return (
      <main className={styles.contentWrap}>
        <CompareLine
          style={styles.compareLine}
          categoryId={categoryId}
          categoryHref={categoryHref}
          location={location}
        />

        <div
          className={styles.returnBtn}
          onClick={() => history.push(categoryHref, { ...history.location.state })}
        >
          <div className={styles.btn} />
          <p>
            {BACK_TO_CATALOGUE}
          </p>
        </div>

        <section className={styles.sliderWrap}>
          <div className={styles.sliderCol}>
            <div className={styles.rangeAttributes}>
              {map(ranges, (attr, index) => (
                <div
                  className={styles.rangeAttribute}
                  key={index}
                >
                  <p className={styles.rangeLabel}>
                    {attr.label}
                  </p>

                  <div className={styles.range}>
                    <div
                      className={styles.filledArea}
                      style={{ width: `${attr.value * 10}%` }}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className={styles.picAttributes}>
              {map(attributesWithPics, (attr, index) => {
                if (!attr.value || !attr.image) return null;

                return (
                  <div
                    className={styles.picAttribute}
                    key={index}
                  >
                    {attr.image && (
                      <Image className={styles.picValue} img={attr.image} alt={attr.image.name} />
                    )}

                    <p className={styles.picLabel}>
                      {attr.label}
                    </p>
                  </div>
                );
              })}
            </div>

            <button
              type="button"
              className={cx(styles.compareBtn, { [styles.disable]: disableCompare })}
              onClick={() => addProductToCompare(id, categoryId)}
            >
              {COMPARE}
            </button>
          </div>

          <ProductSlider name={data.shortName} images={data.images} />

          <div className={styles.sliderCol}>
            <div className={styles.sizeWrap}>
              {productConfig.rightBlock.label || size.label}
              &nbsp;:&nbsp;
              {size.value}
            </div>

            <div className={styles.priceWrap}>
              <p className={styles.priceValue}>
                {currentPrice}
              </p>

              &nbsp;грн/
              {unit.value}
            </div>

            <div className={styles.amount}>
              {amount.value > 0 ? AVAILABLE : NOT_AVAILABLE}
            </div>
          </div>

        </section>

        <div className={styles.cols}>
          <div className={styles.colLeft}>
            <Description description={data.description} />

            <SimilarProducts
              products={similarProducts}
              categoryId={categoryId}
              categoryHref={categoryHref}
              comparedProducts={comparedProducts}
              addProductToCompare={addProductToCompare}
            />
          </div>

          <div className={styles.colRight}>
            <OtherAttributes
              attributes={data.attributes}
              categoryId={categoryId}
            />

            <OtherProductColors
              products={otherProductColors}
              categoryId={categoryId}
              categoryHref={categoryHref}
            />
          </div>
        </div>

        <ViewedProducts
          match={match}
          categoryId={categoryId}
          categoryHref={categoryHref}
          comparedProducts={comparedProducts}
          addProductToCompare={addProductToCompare}
        />
      </main>
    );
  }
}

const mapStateToProps = (state, props) => {
  const categoryId = props.history.location.state.categoryId;
  const comparedProducts = getComparedProductsByCategoryId(categoryId);

  return {
    comparedProducts,
  };
};

export default connect(mapStateToProps, {
  addProductToCompare: addProductToCompareAction,
})(ProductPage);
