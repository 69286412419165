import queryString from 'query-string';
import omit from '../../../../utils/omit';

export const parseQueryString = (
  {
    string,
  },
) => {
  let parsedString = string;
  if (queryString.parse(parsedString)) {
    parsedString = queryString.parse(parsedString);
    if (parsedString) {
      const keys = Object.keys(parsedString);
      if (keys && keys.length === 0) { return parsedString; }
      keys.forEach((key) => {
        if (parsedString[key] && Object.keys(parsedString[key])) {
          try {
            parsedString[key] = JSON.parse(parsedString[key]);
          } catch (err) {
            console.log(err.message);
          }
        }
      });
      return parsedString;
    }
  }
  return parsedString;
};

export const deleteFromMultipleFilter = (
  {
    match,
    history,
    value,
    _id,
    parsedSearchFilters,
    parsedSearch,
  },
) => {
  const newFilters = parsedSearchFilters;
  newFilters[_id] = newFilters[_id].filter((element) => {
    return element !== value;
  });

  const filtersEmpty = Object.keys(newFilters).length === 1 && newFilters[_id].length === 0;
  if (newFilters[_id].length === 0) {
    delete newFilters[_id];
  }
  const searchObj = filtersEmpty
    ? omit(parsedSearch, 'filters')
    : { ...parsedSearch, filters: JSON.stringify(newFilters) };

  history.push({
    path: match,
    search: queryString.stringify(searchObj),
  });
};

export const setMultipleFilter = (
  {
    location,
    match,
    history,
    value,
    _id,
  },
) => {
  const parsedSearch = parseQueryString({ string: location.search });
  const parsedSearchFilters = parsedSearch.filters ? parsedSearch.filters : {};
  if (!parsedSearchFilters[_id]) {
    parsedSearchFilters[_id] = [];
  }

  if (parsedSearchFilters[_id] && parsedSearchFilters[_id].includes(value)) {
    return deleteFromMultipleFilter(
      {
        match,
        history,
        value,
        _id,
        parsedSearchFilters,
        parsedSearch,
      },
    );
  }

  parsedSearchFilters[_id].push(value);

  return history.push({
    path: match,
    search: queryString.stringify({ ...parsedSearch, filters: JSON.stringify(parsedSearchFilters) }),
  });
};

export const setSingleFilter = (
  {
    location,
    match,
    history,
    value,
    _id,
  },
) => {
  const parsedSearch = parseQueryString({ string: location.search });
  const parsedSearchFilters = parsedSearch.filters || { [_id]: null };
  parsedSearchFilters[_id] = value;

  return history.push({
    path: match,
    search: queryString.stringify({ ...parsedSearch, filters: JSON.stringify(parsedSearchFilters) }),
  });
};


export const deleteFromSingleFilter = (
  {
    parsedSearchFilters,
    parsedSearch,
    match,
    history,
    _id,
  },
) => {
  const newFilters = omit(parsedSearchFilters, _id);
  const filtersEmpty = Object.keys(newFilters).length === 0;

  const searchObj = filtersEmpty
    ? omit(parsedSearch, 'filters')
    : { ...parsedSearch, filters: JSON.stringify(newFilters) };

  history.push({
    path: match,
    search: queryString.stringify(searchObj),
  });
};

export const clearFilters = (
  {
    location,
    match,
    history,
  },
) => {
  const parsedSearch = parseQueryString({ string: location.search });
  const searchObj = omit(parsedSearch, 'filters');
  history.push({
    path: match,
    search: queryString.stringify(searchObj),
  });
};
