import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import cx from 'classnames';
import Checkbox from '../../Checkbox';
import { parseQueryString, setSingleFilter } from '../helpers/setAndParseQueryString';
import styles from '../css/styles.css';
import { RADIO } from '../../../consts';

const onChange = (
  {
    match,
    location,
    history,
    value,
    optionsId,
  },
) => () => {
  setSingleFilter({
    location,
    match,
    history,
    value,
    _id: optionsId,
  });
};

const RadioButtonsFilter = (
  {
    item,
    location,
    history,
    match,
  },
) => {
  if (!item) { return null; }

  const options = item.options;
  if (!options || !options.length || !item) { return null; }

  const parsedSearch = parseQueryString({ string: location.search });

  return (
    <div className={cx({ [styles.complicatedWrap]: item.withImages })}>
      {options.map((option) => {
        if (!option) { return null; }

        const checked = (parsedSearch
          && parsedSearch.filters
          && parsedSearch.filters[item._id]
          && parsedSearch.filters[item._id] === option._id
        ) || false;

        return (
          <Checkbox
            id={option._id}
            checked={checked}
            label={option.label}
            type={RADIO}
            image={option.image}
            withImages={item.withImages}
            onChange={onChange({
              match,
              location,
              history,
              optionsId: item._id,
              value: option._id,
            })}
            key={option._id}
          />
        );
      })}
    </div>
  );
};

RadioButtonsFilter.propTypes = {
  item: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(RadioButtonsFilter);
