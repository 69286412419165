import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import GridIcon from './GridIcon';
import GridIconTile from './GridIconTile';
import SortLink from './SortLink';
import styles from '../css/sortPanelLinks.css';
import Checkbox from '../../../components/Checkbox';
import { parseQueryString } from '../../../components/Filter/helpers/setAndParseQueryString';

const sort = [
  {
    name: () => 'низкая ',
    type: 'price',
    field: 'sort',
  },
  { name: () => '/' },
  {
    name: () => ' высокая',
    type: '-price',
    field: 'sort',
  },
];

const view = [
  {
    name: (isActive) => <GridIcon isActive={isActive} />,
    type: 'swatch',
    field: 'view',
    default: true,
  },
  { name: (isActive) => <GridIconTile isActive={isActive} />, type: 'grid', field: 'view' },
];

const toggleStock = (history, match, location, discountId) => () => {
  const parsedSearch = parseQueryString({ string: location.search });
  const parsedSearchFilters = parsedSearch.filters || {};
  const discountValue = parsedSearchFilters[discountId];

  if (discountValue) {
    delete parsedSearchFilters[discountId];
  } else {
    parsedSearchFilters[discountId] = true;
  }

  return history.push({
    path: match,
    search: queryString.stringify({ ...parsedSearch, filters: JSON.stringify(parsedSearchFilters) }),
  });
};

const SortPanelLinks = ({
  history,
  match,
  location,
  discountId,
}) => {
  const parsedSearch = parseQueryString({ string: location.search });
  const stockIsChecked = (parsedSearch.filters && parsedSearch.filters[discountId]) || false;

  return (
    <div className={styles.wrap}>
      <div className={styles.sortWrap}>
        <div>
          {'Цена: '}
          {sort.map((link, index) => (
            <SortLink
              key={index}
              toggle
              location={location}
              match={match}
              link={link}
            />
          ))}
        </div>

        <div className={styles.stock}>
          <Checkbox
            className={styles.stockCheckbox}
            id='stock-checkbox'
            checked={stockIsChecked}
            label='Акция'
            onChange={toggleStock(history, match, location, discountId)}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        {view.map((link, index) => (
          <SortLink
            key={index}
            className={styles.viewLink}
            location={location}
            match={match}
            link={link}
          />
        ))}
      </div>
    </div>
  );
};

SortPanelLinks.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  discountId: PropTypes.string.isRequired,
};

export default SortPanelLinks;
