import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import SingleBanner from '../../components/Banner/components/SingleBanner';
import CategoryContent from './components/CategoryContent';

export class Category extends Component {
  state = {
    category: null,
    products: null,
    priceId: null,
    discountId: null,
    unitId: null,
    productsLength: null,
    filtersAttributes: null,
    maxPrice: 1000,
    minPrice: 0,
  };

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.getProducts(this.props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isPropsEqual = isEqual(nextProps, this.props);
    const isStateEqual = isEqual(nextState, this.state);

    if (isPropsEqual && isStateEqual) { return false; }
    return true;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search
      || nextProps.match.params.href !== this.props.match.params.href
    ) {
      this.getProducts(nextProps);
    }
  }

  getProducts = (props) => {
    const { match, location } = props;
    const href = match.params.href;

    props.getApi(`products/${href}${location.search}`)
      .then(({
        products,
        category,
        priceId,
        discountId,
        unitId,
        productsLength,
        filtersAttributes,
        dictionaries,
        maxPrice,
        minPrice,
      } = {}) => {
        this.setState({
          products,
          category,
          priceId,
          discountId,
          unitId,
          productsLength,
          dictionaries,
          filtersAttributes,
          maxPrice,
          minPrice,
        });
      })
      .catch((error) => console.log('Error on category page fetch', error)); // eslint-disable-line
  };

  render() {
    const {
      products,
      category,
      priceId,
      discountId,
      unitId,
      productsLength,
      maxPrice,
      minPrice,
      dictionaries,
      filtersAttributes,
    } = this.state;
    const { location, match, history } = this.props;

    if (!category || isEmpty(category)) { return null; }

    const bannerTitle = category && category.bannerTitle;
    const bannerDescription = category && category.bannerDescription;
    const banner = category && category.image;
    const { showBanner } = category;

    return (
      <div>
        {showBanner && (
          <SingleBanner
            title={bannerTitle}
            image={banner}
            description={bannerDescription}
          />
        )}

        <CategoryContent
          category={category}
          products={products}
          priceId={priceId}
          maxPrice={maxPrice}
          minPrice={minPrice}
          productsLength={productsLength}
          discountId={discountId}
          filtersAttributes={filtersAttributes}
          unitId={unitId}
          location={location}
          history={history}
          match={match}
          dictionaries={dictionaries}
        />
      </div>
    );
  }
}

export default Category;
