import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RangeComp from 'rc-slider/lib/Range';

import Input from '../Input';

import styles from './css/styles.css';
import './css/range.css';
import { parseQueryString, setSingleFilter } from '../Filter/helpers/setAndParseQueryString';

class Range extends Component {
  static propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    _id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.setMinMaxValue(props);
  }

  componentWillReceiveProps(nextProps) {
    if ((this.props.min !== nextProps.min)
      || (this.props.max !== nextProps.max)) {
      this.setMinMaxValue(nextProps);
    }
  }

  setMinMaxValue = (props) => {
    let inFilter = {};
    if (props.location.search.length !== 0 && props.location.search.includes(props._id)) {
      const parsedSearch = parseQueryString({ string: props.location.search });
      inFilter = parsedSearch && parsedSearch.filters && parsedSearch.filters[props._id];
    }

    this.state = {
      minValue: inFilter.min || parseInt(props.min, 10),
      maxValue: inFilter.max || parseInt(props.max, 10),
    };
  };

  onMinChange = (e) => {
    this.setState({
      focus: true,
      changedMin: parseInt(e.target.value, 10) || '',
    });
  };

  onMaxChange = (e) => {
    this.setState({
      focus: true,
      changedMax: parseInt(e.target.value, 10) || '',
    });
  };

  onMinBlur = () => {
    const { min } = this.props;
    const { maxValue, changedMin } = this.state;

    if (!changedMin) {
      return;
    }

    let minValue = changedMin;

    if (changedMin > maxValue) {
      minValue = maxValue;
    }

    if (changedMin < parseInt(min, 10)) {
      minValue = parseInt(min, 10);
    }

    this.setState({
      minValue,
      changedMin: null,
      focus: false,
    });
  };

  onMaxBlur = () => {
    const { max } = this.props;
    const { minValue, changedMax } = this.state;

    if (!changedMax) {
      return;
    }

    let maxValue = changedMax;

    if (changedMax < minValue) {
      maxValue = minValue;
    }

    if (changedMax > parseInt(max, 10)) {
      maxValue = parseInt(max, 10);
    }

    this.setState({
      maxValue,
      changedMax: null,
      focus: false,
    });
  };

  onRangeChange = (e) => {
    this.setState({
      minValue: e[0],
      maxValue: e[1],
    });
  };

  onSubmit = () => {
    const {
      location,
      match,
      history,
      _id,
    } = this.props;
    const { minValue, maxValue } = this.state;
    const value = { min: minValue, max: maxValue };
    setSingleFilter({
      location,
      match,
      history,
      value,
      _id,
    });
  };

  render() {
    const {
      min,
      max,
      location,
      _id,
    } = this.props;

    const {
      minValue,
      maxValue,
      changedMin,
      changedMax,
      focus,
    } = this.state;

    let inFilter = { min, max };
    if (location.search.length !== 0 && location.search.includes(_id)) {
      const parsedSearch = parseQueryString({ string: location.search });
      inFilter = parsedSearch && parsedSearch.filters && parsedSearch.filters[_id];
    }

    const inputMinValue = focus ? changedMin : minValue;
    const inputMaxValue = focus ? changedMax : maxValue;

    const showSubmit = inFilter.min !== minValue || inFilter.max !== maxValue;

    return (
      <div className={styles.range}>
        <div className={styles.values}>
          <Input type='number' className={styles.input} value={inputMinValue} onBlur={this.onMinBlur} onChange={this.onMinChange} />
          <span> - </span>
          <Input type='number' className={styles.input} value={inputMaxValue} onBlur={this.onMaxBlur} onChange={this.onMaxChange} />
          <span className={styles.label}>грн</span>
        </div>

        <RangeComp
          min={min}
          max={max}
          value={[minValue, maxValue]}
          onChange={this.onRangeChange}
          allowCross={false}
        />

        {
          showSubmit
          && <button className={styles.submit} type="button" onClick={this.onSubmit} />
        }
      </div>
    );
  }
}

export default Range;
