import React, { Component } from 'react';

import Banner from '../components/Banner';
import Card from '../components/Card';

import getApi from '../../utils/getApi';

class Main extends Component {
  state = {
    banners: null,
    cards: null,
  };

  componentDidMount() {
    this.getBanners();
    this.getCards();
  }

  getBanners = () => {
    getApi('collection/banners')
      .then((banners) => { this.setState({ banners: banners.banners }); });
  };

  getCards = () => {
    getApi('collection/mainPageBlocks')
      .then((cards) => { this.setState({ cards: cards.mainPageBlocks }); });
  };

  render() {
    const { banners, cards } = this.state;

    return (
      <div>

        {
          banners
          && <Banner data={banners} />
        }

        {
          cards
          && cards.map((item, index) => (
            <Card
              title={item.title}
              subtitle={item.subtitle}
              text={item.description}
              img={item.image}
              key={`${item.image}-${index}`} //eslint-disable-line
            />
          ))
        }
      </div>
    );
  }
}

export default Main;
