import React from 'react';
import PropTypes from 'prop-types';

import styles from '../css/description.css';
import { ABOUT_PRODUCT } from '../../../consts';

const Description = ({ description }) => {
  if (!description) return null;

  return (
    <section className={styles.wrap}>
      <h2 className={styles.title}>{ABOUT_PRODUCT}</h2>

      <p className={styles.text} dangerouslySetInnerHTML={{ __html: description }} />
    </section>
  );
};

Description.propTypes = {
  description: PropTypes.string,
};

Description.defaultProps = {
  description: '',
};

export default Description;
