import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import cx from 'classnames';

import { postApi } from '../../../../utils/getApi';
import {
  VIEWED_PRODUCTS,
  PRODUCTS_DATA,
  VALUE,
  UNIT,
  IMAGES,
  SHORT_NAME,
  PRICE,
  ID,
  NAME,
  VIEWED,
  DISCOUNT,
  COMPARE,
} from '../../../consts';
import styles from '../css/viewedProducts.css';
import Image from '../../../components/Image';

export class ViewedProducts extends Component {
  static propTypes = {
    categoryId: PropTypes.string.isRequired,
    categoryHref: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired,
    comparedProducts: PropTypes.array,
    addProductToCompare: PropTypes.func.isRequired,
  };

  static defaultProps = {
    comparedProducts: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };

    this.getViewedProductsData(this.props.match);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.href !== this.props.match.params.href) {
      this.getViewedProductsData(nextProps.match);
    }
  }

  getViewedProductsData = (match) => {
    const viewedProducts = JSON.parse(localStorage.getItem(VIEWED_PRODUCTS));
    const excludeCurrentProduct = viewedProducts.filter((product) => product !== match.params.href);

    postApi(PRODUCTS_DATA, { body: { products: excludeCurrentProduct } })
      .then((data) => {
        if (!data) return;

        this.setState({
          data,
        });
      })
      .catch((error) => console.log('Error on category page fetch', error)); // eslint-disable-line
  };

  render() {
    const {
      categoryId,
      categoryHref,
      addProductToCompare,
      comparedProducts,
    } = this.props;
    const { data } = this.state;

    if (!data) return null;

    return (
      <div className={styles.viewedProductsWrap}>
        <h2 className={styles.title}>
          {VIEWED}
        </h2>

        <div className={styles.viewedProducts}>
          {map(data, (product) => {
            const images = get(product, IMAGES, [{ name: NAME, src: '' }]);
            const image = find(images, (item) => item.position === 0) || images[0];
            const name = get(product, SHORT_NAME, '');
            const price = get(product.attributes, [DISCOUNT, VALUE], 0) || get(product.attributes, [PRICE, VALUE], 0);
            const roundedPrice = price.toFixed(2);
            const unit = get(product.attributes, [UNIT, VALUE], '');
            const _id = get(product, ID, '');
            const seoURL = get(product, 'seoURL', null);
            const productLink = `/product/${seoURL || _id}`;

            const disableCompare = comparedProducts.includes(_id) || comparedProducts.length >= 5;

            return (
              <div
                className={styles.productCard}
                key={product._id}
              >
                <div className={styles.imgWrap}>
                  <Image img={image} className={styles.productImg} />
                  <div className={styles.buttonsWrap}>
                    <Link to={{ pathname: productLink, state: { categoryId, categoryHref } }} className={styles.navLink}>
                      <button type='button' className={styles.button}>описание</button>
                    </Link>

                    <button
                      type="button"
                      className={cx(styles.button, { [styles.disable]: disableCompare })}
                      onClick={() => addProductToCompare(_id, categoryId)}
                    >
                      {COMPARE}
                    </button>
                  </div>
                </div>

                <Link to={{ pathname: productLink, state: { categoryId, categoryHref } }} className={styles.innerWrap}>
                  <div className={styles.productName}>
                    {name}
                  </div>

                  <div className={styles.productPrice}>
                    <span>
                      {roundedPrice}
                    </span>
                    &nbsp;грн/
                    {unit}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default ViewedProducts;
