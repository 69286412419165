exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".nav__1aRyX {\n  width: 1200px;\n  height: 60px;;\n  margin: 0 auto;\n  padding: 0 30px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  list-style: none;\n}\n\n.navItem__1GH9l {\n  font-weight: 600;\n  font-size: 12px;\n  color: #2e2e2e;\n  letter-spacing: 1px;\n  white-space: nowrap;\n}\n\n.navItem__1GH9l:hover {\n  opacity: 0.8;\n}\n\n.navLink__1pZYc {\n  text-decoration: none;\n  color: inherit;\n  text-transform: uppercase;\n}\n\n.navLink__1pZYc:visited {\n  color: inherit;\n}", ""]);

// exports
exports.locals = {
	"nav": "nav__1aRyX",
	"navItem": "navItem__1GH9l",
	"navLink": "navLink__1pZYc"
};