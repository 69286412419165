import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from '../css/bannerArticle.css';

const BannerArticle = (
  {
    title,
    description,
    stylesArticle,
    stylesTextBox,
  },
) => (
  <div className={cx(styles.article, stylesArticle)}>
    <div className={cx(styles.textBox, stylesTextBox)}>
      <h1 className={styles.title}>
        {title}
      </h1>

      <p
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: description }} // eslint-disable-line
      />
    </div>
  </div>
);

BannerArticle.propTypes = {
  title: PropTypes.string,
  stylesArticle: PropTypes.string,
  stylesTextBox: PropTypes.string,
  description: PropTypes.string,
};

BannerArticle.defaultProps = {
  stylesArticle: '',
  stylesTextBox: '',
  description: '',
  title: '',
};

export default BannerArticle;
