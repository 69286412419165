import React from 'react';
import PropTypes from 'prop-types';

import styles from '../css/styles.css';

const Paragraph = ({ text }) => (
  <p
    className={styles.paragraph}
    dangerouslySetInnerHTML={{ __html: text }} //eslint-disable-line
  />
);

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Paragraph;
