exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".banner__3uxC6 {\n  position: relative;\n  max-width: 1920px;\n  overflow: hidden;\n  height: 240px;\n  margin: 0 auto;\n}\n\n.article__3_rkq {\n  position: absolute;\n  left: 25%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  top: 50%;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: 460px;\n  height: 100%;\n  background-color: rgba(0, 0, 0, .3);\n}\n\n.textBox__3K6dW {\n  max-width: 300px;\n  margin: 0 0 0 80px;\n}\n\n.image__12lux {\n  width: 100%;\n  border: 0;\n  position: absolute;\n  -webkit-transform: translate(0, -50%);\n          transform: translate(0, -50%);\n  top: 50%;\n  left: 0;\n}", ""]);

// exports
exports.locals = {
	"banner": "banner__3uxC6",
	"article": "article__3_rkq",
	"textBox": "textBox__3K6dW",
	"image": "image__12lux"
};