import React from 'react';
import PropTypes from 'prop-types';
import SortPanelButtons from './SortPanelLinks';
import styles from '../css/sortPanel.css';

const SortPanel = (
  {
    category,
    match,
    location,
    productsLength,
    discountId,
    history,
  },
) => {
  if (!category) { return null; }
  return (
    <div id='sortpanel' className={styles.sortPanel}>
      <div className={styles.title}>
        {category.label}
      </div>
      <div className={styles.counter}>
        {`${productsLength} вариантов`}
      </div>
      <SortPanelButtons
        history={history}
        match={match}
        location={location}
        discountId={discountId}
      />
    </div>
  );
};

SortPanel.propTypes = {
  history: PropTypes.object.isRequired,
  category: PropTypes.object,
  productsLength: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  discountId: PropTypes.string.isRequired,
};

SortPanel.defaultProps = {
  category: {},
};

export default SortPanel;
