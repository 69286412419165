exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".map__2-3St {\n  height: 280px;\n  position: absolute;\n  width: 100%;\n  bottom: 90px;\n}\n\n.wrap__EQomV {\n  width: 346px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-transform: translate(-100%, -50%);\n          transform: translate(-100%, -50%);\n}\n\n.contacts__2DiQA {\n  width: 294px;\n  padding: 12px 30px;\n  -webkit-box-shadow: 0 10px 16px rgba(64, 64, 64, .05);\n          box-shadow: 0 10px 16px rgba(64, 64, 64, .05);\n  background-color: rgba(37, 37, 37, .8);\n  color: #fdfdfd;\n}\n\n.title__ULIc6 {\n  margin-bottom: 6px;\n  color: #ffffff;\n  font-size: 14px;\n  font-weight: 700;\n  line-height: 20px;\n  letter-spacing: 2.8px;\n  text-transform: uppercase;\n  text-align: center;\n}\n\n.title__ULIc6 img {\n  width: 90px;\n}\n\n.days__1CpRu {\n  margin-left: 5px;\n}\n\n.text__PoU3M {\n  font-size: 12px;\n  line-height: 22px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  letter-spacing: 0.36px;\n}\n\n.text__PoU3M + .phone__2Kycp {\n  margin-top: 14px;\n}\n\n.phone__2Kycp {\n  font-size: 18px;\n  line-height: 24px;\n  letter-spacing: 0.54px;\n}\n\n.pinHolder__27ADo {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin: 0 0 0 18px;\n}", ""]);

// exports
exports.locals = {
	"map": "map__2-3St",
	"wrap": "wrap__EQomV",
	"contacts": "contacts__2DiQA",
	"title": "title__ULIc6",
	"days": "days__1CpRu",
	"text": "text__PoU3M",
	"phone": "phone__2Kycp",
	"pinHolder": "pinHolder__27ADo"
};