import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import Contacts from './components/Contacts';
import { GOOGLE_API_KEY } from '../../../config';

import styles from './css/styles.css';

class GoogleMap extends PureComponent {
  static propTypes = {
    zoom: PropTypes.number,
    days: PropTypes.array.isRequired,
    center: PropTypes.object.isRequired,
    phone: PropTypes.string.isRequired,
    phone1: PropTypes.string.isRequired,
    phone2: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
  };

  static defaultProps = {
    zoom: 17,
  };

  render() {
    const {
      center,
      zoom,
      days,
      phone,
      phone1,
      phone2,
      address,
    } = this.props;

    const lat = Number(center.lat);
    const lng = Number(center.lng);
    const bootstrapURLKeys = { key: GOOGLE_API_KEY };
    const options = { disableDefaultUI: true };

    return (
      <div className={styles.map}>
        <GoogleMapReact
          bootstrapURLKeys={bootstrapURLKeys}
          defaultCenter={center}
          defaultZoom={zoom}
          options={options}
        >
          <Contacts
            lat={lat}
            lng={lng}
            address={address}
            days={days}
            phone={phone}
            phone1={phone1}
            phone2={phone2}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;
