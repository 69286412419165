import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';

import getApi from '../utils/getApi';
import Header from './components/Header';
import GoogleMap from './components/Map';
import Footer from './components/Footer';
import Navigation from './components/Navigation';

import Main from './pages/main';
import Dummy from './pages/dummy';
import Category from './pages/Category';
import ProductPage from './pages/Product';
import ComparePage from './pages/Compare';

import './styles/reset.css';
import './styles/fonts.css';
import styles from './styles/app.css';

class App extends Component {
  state = {
    contacts: null,
    categories: null,
  };

  componentDidMount() {
    this.getContacts();
    this.getCategories();
  }

  getContacts = () => {
    getApi('collection/contacts')
      .then((contacts) => { this.setState({ contacts: contacts.contacts[0] }); });
  };

  getCategories = () => {
    getApi('collection/categories')
      .then((result) => {
        this.setState({ categories: result.categories });
      });
  };

  render() {
    const { contacts, categories } = this.state;

    if (!contacts) {
      return null;
    }

    const coordinates = { // TODO: update in DB to object
      lat: Number(contacts['coordinate x']),
      lng: Number(contacts['coordinate y']),
    };

    return (
      <main className={styles.page}>
        <div className={styles.headerWrap}>
          <Header
            days={[contacts.workDays, contacts.workDays1]}
            phone={contacts.phone}
          />

          <Navigation categories={categories} />
        </div>
        <div className={styles.content}>
          <Switch>
            <Route path='/' component={Main} exact />
            <Route path='/dummy' component={Dummy} />
            <Route path='/category/:href' render={(props) => <Category {...props} getApi={getApi} />} />
            <Route path='/product/:href' component={ProductPage} />
            <Route path='/compare/:_id' component={ComparePage} />
          </Switch>
        </div>
        <GoogleMap
          center={coordinates}
          days={[contacts.workDays, contacts.workDays1]}
          address={contacts.address}
          phone={contacts.phone}
          phone1={contacts.phone1}
          phone2={contacts.phone2}
        />

        <Footer
          facebook={contacts.fbLink}
          twitter={contacts.twitterLink}
          google={contacts.googleLink}
          rss={contacts.rssLink}
        />
      </main>
    );
  }
}

export default App;
