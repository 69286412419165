exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".color__1i7QN {\n  width: 33.3333%;\n  float: left;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  margin: 10px 0 0 0;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  cursor: pointer;\n}\n\n.color__1i7QN:nth-child(-n+3) {\n  margin: 0;\n}\n\n.input__1ZTgk {\n  display: none;\n}\n\n.figure__1V7wo {\n  position: relative;\n  width: 32px;\n  height: 32px;\n  border-radius: 100%;\n  background: url('/img/color.png');\n  background-size: cover;\n}\n\n.color__1i7QN:hover .figure__1V7wo,\n.input__1ZTgk:checked + .figure__1V7wo {\n  border: 4px solid #2b2b2b;\n}\n\n.input__1ZTgk:checked + .figure__1V7wo:after {\n  content: '';\n  display: block;\n  width: 100%;\n  height: 100%;\n  border: 1px solid #ffffff;\n  border-radius: 100%;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  -webkit-transform: translate(-50%, -50%);\n          transform: translate(-50%, -50%);\n}\n\n.label__5WM1C {\n  color: #3d3d3d;\n  font-size: 8px;\n  font-weight: 400;\n  line-height: 20px;\n  letter-spacing: 0.4px;\n}\n\n.color__1i7QN:hover .label__5WM1C {\n  font-weight: 600;\n}\n\n.input__1ZTgk:checked ~ .label__5WM1C {\n  font-weight: 700;\n}", ""]);

// exports
exports.locals = {
	"color": "color__1i7QN",
	"input": "input__1ZTgk",
	"figure": "figure__1V7wo",
	"label": "label__5WM1C"
};