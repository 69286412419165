exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".card__1hoD1 {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  max-width: 1200px;\n  margin: 0 auto;\n  padding: 50px 0;\n  border-top: 1px solid #adadad;\n}\n\n.image__3l9je {\n  position: relative;\n  width: 620px;\n  height: 236px;\n  overflow: hidden;\n}\n\n.image__3l9je img {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n}\n\n.card__1hoD1:nth-child(odd) {\n  -webkit-box-pack: end;\n      -ms-flex-pack: end;\n          justify-content: flex-end;\n}\n\n.card__1hoD1:nth-child(odd) .image__3l9je {\n  -webkit-box-ordinal-group: 3;\n      -ms-flex-order: 2;\n          order: 2;\n}\n\n.description__1zaGP {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  width: 390px;\n  margin: 0 0 0 60px;\n  color: #343434;\n}\n\n.card__1hoD1:nth-child(odd) .description__1zaGP {\n  margin: 0 100px 0 0;\n}\n\n.header__1Vtx1 {\n  margin-bottom: 30px;\n}\n\n.title__3p-_c {\n  margin-bottom: 6px;\n  font-size: 21px;\n  font-weight: 700;\n  line-height: 32px;\n  text-transform: uppercase;\n  letter-spacing: 0.7px;\n}\n\n.paragraph___YCYd {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 18px;\n  letter-spacing: 0.45px;\n}\n\n.paragraph___YCYd + .paragraph___YCYd {\n  margin-top: 20px;\n}", ""]);

// exports
exports.locals = {
	"card": "card__1hoD1",
	"image": "image__3l9je",
	"description": "description__1zaGP",
	"header": "header__1Vtx1",
	"title": "title__3p-_c",
	"paragraph": "paragraph___YCYd"
};