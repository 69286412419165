import React from 'react';
import PropTypes from 'prop-types';

import styles from '../css/styles.css';

const SocialList = ({
  facebook,
  twitter,
  google,
  rss,
}) => (
  <ul className={styles.social}>
    <li className={styles.socialItem}>
      <a href={facebook} target='_blank'>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="8" height="15" viewBox="0 0 8 15">
          <defs>
            <path id="bjtza" d="M1039.92 1957.2h-2.95v-7.1h-1.47v-2.45h1.47v-1.47c0-2 .83-3.18 3.2-3.18h1.96v2.45h-1.23c-.92 0-.98.34-.98.98v1.22h2.22l-.26 2.45h-1.96z" />
          </defs>
          <g>
            <g transform="translate(-1035 -1943)">
              <use fill="#b2b2b2" xlinkHref="#bjtza" />
            </g>
          </g>
        </svg>
      </a>
    </li>

    <li className={styles.socialItem}>
      <a href={twitter} target='_blank'>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="14" viewBox="0 0 16 14">
          <defs>
            <path id="s076a" d="M1105.4 1945.27c-.58.25-1.2.42-1.85.5a3.2 3.2 0 0 0 1.41-1.77c-.62.37-1.3.63-2.04.78a3.2 3.2 0 0 0-5.46 2.91 9.1 9.1 0 0 1-6.6-3.34 3.18 3.18 0 0 0 .99 4.27 3.2 3.2 0 0 1-1.45-.4v.04a3.2 3.2 0 0 0 2.57 3.14 3.22 3.22 0 0 1-1.45.05 3.2 3.2 0 0 0 3 2.22 6.44 6.44 0 0 1-4.75 1.33 9.1 9.1 0 0 0 4.91 1.43c5.9 0 9.12-4.87 9.12-9.1v-.4a6.49 6.49 0 0 0 1.6-1.66z" />
          </defs>

          <g>
            <g transform="translate(-1090 -1943)">
              <use fill="#b2b2b2" xlinkHref="#s076a" />
            </g>
          </g>
        </svg>
      </a>
    </li>

    <li className={styles.socialItem}>
      <a href={google} target='_blank'>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18" height="15" viewBox="0 0 18 15">
          <defs>
            <path id="mf7ka" d="M1161.37 1946.98v2.89h-.72v-2.89h-2.9v-.72h2.9v-2.89h.72v2.9h2.9v.71zm-6.84-2.88c.92.72 1.4 1.48 1.4 2.57 0 .92-.5 1.77-1.22 2.33-.7.55-.84.78-.84 1.24 0 .4.75 1.08 1.15 1.36 1.15.8 1.52 1.56 1.52 2.82 0 1.57-1.52 3.13-4.28 3.13-2.42 0-4.46-.98-4.46-2.55 0-1.6 1.86-3.13 4.28-3.13l.76-.01a1.7 1.7 0 0 1-.6-1.2c0-.3.1-.58.23-.83h-.4a3.17 3.17 0 0 1-3.33-3.2c0-1.7 1.84-3.26 3.8-3.26h4.35l-.98.73zm-1.99 8.17c-1.67-.01-3.17 1.02-3.17 2.26 0 1.27 1.21 2.33 2.88 2.33 2.35 0 3.17-1 3.17-2.26 0-.16-.02-.3-.05-.45-.19-.72-.84-1.07-1.74-1.7-.33-.11-.7-.17-1.09-.18zm1.83-5.45c-.2-1.47-1.26-2.65-2.38-2.68-1.12-.04-1.87 1.09-1.68 2.56.2 1.47 1.26 2.69 2.38 2.72 1.12.03 1.88-1.13 1.68-2.6z" />
          </defs>

          <g>
            <g transform="translate(-1147 -1943)">
              <use fill="#b2b2b2" xlinkHref="#mf7ka" />
            </g>
          </g>
        </svg>
      </a>
    </li>

    <li className={styles.socialItem}>
      <a href={rss} target='_blank'>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="15" viewBox="0 0 16 15">
          <defs>
            <path id="eeuna" d="M1214.68 1958c0-6.52-5.3-11.82-11.83-11.82v-2.8c8.08 0 14.65 6.56 14.65 14.62zm-2.16 0h-2.82a6.8 6.8 0 0 0-2-4.85 6.8 6.8 0 0 0-4.85-2v-2.8a9.67 9.67 0 0 1 9.67 9.65zm-7.72-3.9a1.94 1.94 0 0 1 0 3.88 1.94 1.94 0 1 1 0-3.88z" />
          </defs>

          <g>
            <g transform="translate(-1202 -1943)">
              <use fill="#b2b2b2" xlinkHref="#eeuna" />
            </g>
          </g>
        </svg>
      </a>
    </li>
  </ul>
);

SocialList.propTypes = {
  facebook: PropTypes.string.isRequired,
  twitter: PropTypes.string.isRequired,
  google: PropTypes.string.isRequired,
  rss: PropTypes.string.isRequired,
};

export default SocialList;
