import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import sortBy from 'lodash/sortBy';

import styles from '../css/styles.css';
import { PRODUCT_PAGE_SLIDER_TEXT } from '../../../consts';
import Image from '../../../components/Image';

export class ProductSlider extends Component {
  static propTypes = {
    images: PropTypes.array,
    name: PropTypes.string,
  };

  static defaultProps = {
    images: [],
    name: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      activeImgIndex: 0,
      zoomMode: false,
      scale: 1,
    };
  }

  setActiveImg = (index) => {
    this.setState({
      activeImgIndex: index,
    });
  };

  nextImg = () => {
    const { images } = this.props;
    const { activeImgIndex } = this.state;

    if (activeImgIndex !== images.length - 1) {
      this.setActiveImg(activeImgIndex + 1);
    }
  };

  prevImg = () => {
    const { activeImgIndex } = this.state;

    if (activeImgIndex !== 0) {
      this.setActiveImg(activeImgIndex - 1);
    }
  };

  onZoom = () => {
    this.setState({ zoomMode: true });
  };

  offZoom = () => {
    this.setState({ zoomMode: false, scale: 1 });
  };

  onScroll = (ev) => {
    if (this.state.zoomMode) {
      ev.preventDefault();

      if (ev.deltaY > 0) {
        this.zoomIn(0.05);
      }
      if (ev.deltaY < 0) {
        this.zoomOut(0.05);
      }
    }
  };

  zoomIn = (coef) => {
    const { scale } = this.state;
    if (scale < 3) {
      this.setState({ scale: scale + coef });
    }
  };

  zoomOut = (coef) => {
    const { scale } = this.state;
    if (scale > 0.5) {
      this.setState({ scale: scale - coef });
    }
  };

  resetZoom = () => {
    this.setState({ scale: 1 });
  };

  render() {
    const { images, name } = this.props;
    const { activeImgIndex, zoomMode, scale } = this.state;

    if (!images || images.length === 0) return null;

    const sortedImgs = sortBy(images, (image) => image.position);
    const activeImg = sortedImgs[activeImgIndex];
    const coef = 0.1;
    return (
      <div className={styles.productSlider}>
        <div className={styles.name}>
          {name}
        </div>

        <div className={styles.activeImgWrap}>
          <div
            className={styles.prev}
            onClick={this.prevImg}
          />

          <div onClick={this.onZoom}>
            <Image className={cx(styles.activeImg)} img={activeImg} alt={name} />
          </div>

          <div
            className={styles.next}
            onClick={this.nextImg}
          />
        </div>

        <p className={styles.descr}>
          {PRODUCT_PAGE_SLIDER_TEXT}
        </p>

        <div className={styles.navigation}>
          {sortedImgs.map((image, index) => (
            <div
              className={cx(styles.navImg, { active: activeImgIndex === index })}
              onClick={() => { this.setActiveImg(index); }}
              key={index}
            >
              <Image img={image} />
            </div>
          ))}
        </div>
        <div onWheel={this.onScroll} className={cx({ [styles.zoomedBackground]: zoomMode, [styles.zoomedBackgroundOff]: !zoomMode })}>
          <Image
            style={{ transform: `scale(${scale})` }}
            className={cx({ [styles.zoomedImage]: zoomMode, [styles.zoomedImageOff]: !zoomMode })}
            img={activeImg}
            alt={name}
          />
          <div className={styles.buttonsWrap}>
            <button onClick={() => this.zoomIn(coef)} className={styles.zoomButton} type='button'>
              <img src='/img/plus.svg' alt='plus' />
            </button>
            <button onClick={() => this.zoomOut(coef)} className={styles.zoomButton} type='button'>
              <img src='/img/minus.svg' alt='minus' />
            </button>
            <button onClick={this.resetZoom} className={styles.zoomButton} type='button'>
              <img src='/img/reset.svg' alt='reset' />
            </button>
          </div>
          <button onClick={this.offZoom} className={cx(styles.zoomButton, styles.closeButton)} type='button'>
            <img src='/img/cross.svg' alt='close' />
          </button>
        </div>
      </div>
    );
  }
}

export default ProductSlider;
