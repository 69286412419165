import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../Input/component';
import { parseQueryString, setSingleFilter } from '../Filter/helpers/setAndParseQueryString';
import styles from './css/styles.css';

class SingleInputFilter extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    _id: PropTypes.string.isRequired,
  };

  constructor(props) {
    super();
    let value = null;
    if (props.location.search.length !== 0 && props.location.search.includes(props._id)) {
      const parsedSearch = parseQueryString({ string: props.location.search });
      value = parsedSearch && parsedSearch.filters && parsedSearch.filters[props._id];
    }
    this.state = { value };
  }

  onChange = (ev) => {
    this.setState({ value: ev.target.value });
  };

  onSubmit = () => {
    const {
      location,
      match,
      history,
      _id,
    } = this.props;
    const { value } = this.state;
    setSingleFilter({
      location,
      match,
      history,
      value,
      _id,
    });
  };

  render() {
    const {
      location,
      _id,
    } = this.props;
    const value = (parseQueryString({ string: location.search })
      && parseQueryString({ string: location.search }).filters
      && parseQueryString({ string: location.search }).filters[_id]);
    const showSubmit = this.state.value !== value && this.state.value !== null;

    return (
      <div className={styles.wrap}>
        <Input onChange={this.onChange} value={this.state.value} type='number' />
        {
          showSubmit
          && <button className={styles.submit} type="button" onClick={this.onSubmit} />
        }
      </div>);
  }
}

export default SingleInputFilter;
