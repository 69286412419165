import React from 'react';
import PropTypes from 'prop-types';
import CheckboxColor from '../../CheckboxColor';

const ColorFilter = ({ colors, item }) => {
  if (!colors || !colors.length || !item) { return null; }

  return colors.map((color) => (
    <CheckboxColor
      colorsId={item._id}
      bgColor={color.additional}
      id={color._id}
      label={color.label}
      value={color.value}
      key={color._id}
    />
  ));
};

ColorFilter.propTypes = {
  colors: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
};

export default ColorFilter;
